var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-hidden",style:(_vm.wrapperStyles)},[_c('splitpanes',{staticClass:"default-theme h-full",attrs:{"horizontal":"","push-other-panes":false},on:{"resize":function($event){_vm.resizeMap = !_vm.resizeMap}}},[_c('pane',{attrs:{"size":_vm.firstPaneSize,"max-size":"88","min-size":"12"}},[_c('v-lazy',{staticClass:"h-full"},[_c('div',{staticClass:"h-full"},[_c('prp-map',{ref:"excavationOrderMap",staticClass:"h-full",attrs:{"invokeGetIcons":_vm.invokeGetIcons,"options":_vm.options},on:{"pull-data":_vm.pullData,"close-edit-dialog":_vm.closeEditDialog,"open-edit-dialog-map":_vm.openEditDialogMap,"reset-invoke-get-icons":function($event){_vm.invokeGetIcons = false},"pull-fresh-edit-item":function (val) { return _vm.openEditDialog(val); }}})],1)])],1),_c('pane',{staticClass:"overflow-y-auto",attrs:{"size":_vm.secondPaneSize,"min-size":"12","max-size":"88"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('prp-controls',{attrs:{"reset-history":_vm.resetHistory,"open-edit-dialog":_vm.edit,"edit-item-prop":_vm.editItem,"options":_vm.options,"map-marker-clicked":_vm.clickedMapMarker,"to-date":_vm.toDateFromCreateOrUpdate},on:{"update:options":function($event){_vm.options=$event},"close-edit-dialog":_vm.closeEditDialog,"show-table":_vm.showTable,"get-options":_vm.getOptions,"pull-data":_vm.pullData,"pull-fresh-edit-item":function (val) { return _vm.openEditDialog(val); }}}),(_vm.tableVisible)?_c('v-data-table',{directives:[{name:"stripped",rawName:"v-stripped"}],staticClass:"fill-height overflow-y-auto",attrs:{"headers":_vm.header,"item-key":"id","items":_vm.list,"options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.perPage,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15]
                }},on:{"update:options":[function($event){_vm.options=$event},_vm.getItems],"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.applicant",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.applicant && item.applicant.name ? item.applicant.name : '-')+" ")]}},{key:"item.investor",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.investor && item.investor.name ? item.investor.name + (item.investor.email ? ' (' + item.investor.email + ')' : '') : '-')+" ")]}},{key:"item.geolocation",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.geolocation && item.geolocation.address ? item.geolocation.address : '-')+" ")]}},{key:"item.consent_number",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.consent_number ? item.consent_number : '-')+" ")]}},{key:"item.start_date",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.start_date ? _vm.formatSqlDateTime(item.start_date, _vm.tableExtensionsDatetimeFormat) : '-')+" ")]}},{key:"item.end_date",fn:function(ref){
                var item = ref.item;
return [(item.extensions.length > _vm.maxShowEndDatesInTable)?_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"cursor-default-style-on-hover"},'div',attrs,false),on),[_vm._v(" "+_vm._s((item.end_date ? _vm.formatSqlDateTime(item.end_date, _vm.tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : ''))+" "),_vm._l((item.extensions.slice(0, _vm.maxShowEndDatesInTable - 1)),function(value,valueId){return [(value.end_date)?_c('div',{key:valueId},[_vm._v(" "+_vm._s((value.end_date ? _vm.formatSqlDateTime(value.end_date, _vm.tableExtensionsDatetimeFormat) : '-') + (valueId !== undefined && valueId !== null && valueId !== item.extensions.length - 1 ? ', ' : '' ))+" ")]):_vm._e()]}),(item.extensions.length > _vm.maxShowEndDatesInTable)?_c('div',[_vm._v(" ... ")]):_vm._e()],2)]}}],null,true)},[_vm._v(" "+_vm._s((item.end_date ? _vm.formatSqlDateTime(item.end_date, _vm.tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : ''))+" "),_vm._l((item.extensions),function(value,valueId){return [(value && value.end_date)?_c('div',{key:valueId},[_vm._v(" "+_vm._s((value.end_date ? _vm.formatSqlDateTime(value.end_date, _vm.tableExtensionsDatetimeFormat) : '-') + (valueId !== undefined && valueId !== null && valueId !== item.extensions.length - 1 ? ', ' : '' ))+" ")]):_vm._e()]})],2):_c('div',[_vm._v(" "+_vm._s((item.end_date ? _vm.formatSqlDateTime(item.end_date, _vm.tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : ''))+" "),_vm._l((item.extensions),function(value,valueId){return [(value && value.end_date)?_c('div',{key:valueId},[_vm._v(" "+_vm._s(value.end_date ? _vm.formatSqlDateTime(value.end_date, _vm.tableExtensionsDatetimeFormat) : '-')+" ")]):_vm._e()]})],2),((item.extensions && !item.extensions.length) && !item.end_date)?[_vm._v(" - ")]:_vm._e()]}},{key:"item.zc_responsible_person",fn:function(ref){
                var item = ref.item;
return [(item && item.zc_responsible_person && item.zc_responsible_person.length)?[(item.zc_responsible_person.length > _vm.maxShowResponsiblePeopleInTable)?_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"cursor-default-style-on-hover"},'div',attrs,false),on),[_vm._l((item.zc_responsible_person.slice(0, _vm.maxShowResponsiblePeopleInTable)),function(person,personId){return [(person && person.name)?_c('div',{key:personId},[_vm._v(" "+_vm._s(person.name + ( person.email ? ' (' + person.email + ')' : '' ) + ( personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1 ? ', ' : '' ))+" ")]):_vm._e()]}),(item.zc_responsible_person.length > _vm.maxShowResponsiblePeopleInTable)?_c('div',[_vm._v(" ... ")]):_vm._e()],2)]}}],null,true)},[_vm._l((item.zc_responsible_person),function(person,personId){return [(person && person.name)?_c('div',{key:personId},[_vm._v(" "+_vm._s(person.name + ( person.email ? ' (' + person.email + ')' : '' ) + ( personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1 ? ', ' : '' ))+" ")]):_vm._e()]})],2):_c('div',[_vm._l((item.zc_responsible_person),function(person,personId){return [(person && person.name)?_c('div',{key:personId},[_vm._v(" "+_vm._s(person.name + ( person.email ? ' (' + person.email + ')' : '' ) + ( personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1 ? ', ' : '' ))+" ")]):_vm._e()]})],2)]:[_vm._v(" - ")]]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.status && item.status.description ? _vm.$t(item.status.description) : '-')+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"d-flex align-center list-item-min-height",attrs:{"link":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-list-item-icon',{staticClass:"align-self-center my-0 mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-list-item-title',{staticClass:"main-font-size"},[_vm._v(" "+_vm._s(_vm.$t('base.edit'))+" ")])],1),_c('v-list-item',{staticClass:"d-flex align-center list-item-min-height",attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-list-item-icon',{staticClass:"align-self-center my-0 mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-title',{staticClass:"main-font-size"},[_vm._v(" "+_vm._s(_vm.$t('base.delete'))+" ")])],1)],1)],1)]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1),_c('delete-prp-modal',{attrs:{"open-modal":_vm.openDeleteOrderModal,"id":_vm.deleteId},on:{"close-work-order-confirmed":_vm.deleteConfirmed,"close-work-order-canceled":function($event){_vm.openDeleteOrderModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }