<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        :size="firstPaneSize"
        max-size="88"
        min-size="12"
      >
        <v-lazy class="h-full">
          <div class="h-full">
            <prp-map
              ref="excavationOrderMap"
              class="h-full"
              :invokeGetIcons="invokeGetIcons"
              :options="options"
              @pull-data="pullData"
              @close-edit-dialog="closeEditDialog"
              @open-edit-dialog-map="openEditDialogMap"
              @reset-invoke-get-icons="invokeGetIcons = false"
              @pull-fresh-edit-item="val => openEditDialog(val)"
            />
          </div>
        </v-lazy>
      </pane>
      <pane
        :size="secondPaneSize"
        min-size="12"
        max-size="88"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-card>
            <v-card-text>
              <prp-controls
                :reset-history="resetHistory"
                :open-edit-dialog="edit"
                :edit-item-prop="editItem"
                :options.sync="options"
                :map-marker-clicked="clickedMapMarker"
                :to-date="toDateFromCreateOrUpdate"
                @close-edit-dialog="closeEditDialog"
                @show-table="showTable"
                @get-options="getOptions"
                @pull-data="pullData"
                @pull-fresh-edit-item="val => openEditDialog(val)"
              />
              <v-data-table
                v-if="tableVisible"
                v-stripped
                class="fill-height overflow-y-auto"
                :headers="header"
                item-key="id"
                :items="list"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.perPage"
                :sort-by.sync="options.sortBy"
                :sort-desc.sync="options.sortDesc"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15]
                }"
                @update:options="getItems"
              >
<!--                Applicant-->
                <template #[`item.applicant`]="{ item }">
                  {{ item && item.applicant && item.applicant.name ? item.applicant.name : '-' }}
                </template>

                <template #[`item.investor`]="{ item }">
                  {{ item && item.investor && item.investor.name ? item.investor.name + (item.investor.email ? ' (' + item.investor.email + ')' : '') : '-' }}
                </template>

<!--                Address-->
                <template #[`item.geolocation`]="{ item }">
                  {{ item && item.geolocation && item.geolocation.address ? item.geolocation.address : '-' }}
                </template>

<!--                Consent number-->
                <template #[`item.consent_number`]="{ item }">
                  {{ item && item.consent_number ? item.consent_number : '-' }}
                </template>

<!--                Start date-->
                <template #[`item.start_date`]="{ item }">
                  {{ item && item.start_date ? formatSqlDateTime(item.start_date, tableExtensionsDatetimeFormat) : '-' }}
                </template>

<!--                Extensions-->
                <template #[`item.end_date`]="{ item }"
                >
<!--                    Tooltip for displaying all extensions if they exceed allowed display limit-->
                  <v-tooltip v-if="item.extensions.length > maxShowEndDatesInTable"
                   color="black"
                   top
                  >
<!--                      Cell value-->
                    <template
                      #activator="{ on, attrs }"
                    >
                      <div
                        v-on="on"
                        v-bind="attrs"
                        class="cursor-default-style-on-hover"
                      >
                        {{ (item.end_date ? formatSqlDateTime(item.end_date, tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : '') }}
                        <template
                          v-for="(value, valueId) in item.extensions.slice(0, maxShowEndDatesInTable - 1)"
                        >
                          <div
                            v-if="value.end_date"
                            :key="valueId"
                          >
                            {{ (value.end_date ? formatSqlDateTime(value.end_date, tableExtensionsDatetimeFormat) : '-') +
                            (valueId !== undefined && valueId !== null && valueId !== item.extensions.length - 1
                            ? ', '
                            : ''
                            )}}
                          </div>
                        </template>
                        <div
                          v-if="item.extensions.length > maxShowEndDatesInTable"
                        >
                          ...
                        </div>
                      </div>
                    </template>

                    {{ (item.end_date ? formatSqlDateTime(item.end_date, tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : '') }}
<!--                      Tooltip content-->
                    <template
                      v-for="(value, valueId) in item.extensions"
                    >
                      <div
                        v-if="value && value.end_date"
                        :key="valueId"
                      >
                        {{ (value.end_date ? formatSqlDateTime(value.end_date, tableExtensionsDatetimeFormat) : '-') +
                        (valueId !== undefined && valueId !== null && valueId !== item.extensions.length - 1
                            ? ', '
                            : ''
                        )}}
                      </div>
                    </template>
                  </v-tooltip>
<!--                    Otherwise, display all extensions (limit is not exceeded)-->
                  <div v-else
                  >
                    {{ (item.end_date ? formatSqlDateTime(item.end_date, tableExtensionsDatetimeFormat) : '') + (item.extensions && item.extensions.length > 1 ? ',' : '') }}
                    <template
                      v-for="(value, valueId) in item.extensions"
                    >
                      <div
                        v-if="value && value.end_date"
                        :key="valueId"
                      >
                        {{ value.end_date ? formatSqlDateTime(value.end_date, tableExtensionsDatetimeFormat) : '-' }}
                      </div>
                    </template>
                  </div>

                  <template
                    v-if="(item.extensions && !item.extensions.length) && !item.end_date"
                  >
                    -
                  </template>
                </template>

<!--                ZC responsible people-->
                <template #[`item.zc_responsible_person`]="{ item }"
                >
                  <template v-if="item && item.zc_responsible_person && item.zc_responsible_person.length"
                  >
<!--                    Tooltip for displaying all extensions if they exceed allowed display limit-->
                    <v-tooltip v-if="item.zc_responsible_person.length > maxShowResponsiblePeopleInTable"
                      color="black"
                      top
                    >
<!--                      Activator-->
                      <template
                        #activator="{ on, attrs }"
                      >
                        <div
                          v-on="on"
                          v-bind="attrs"
                          class="cursor-default-style-on-hover"
                        >
                          <template
                            v-for="(person, personId) in item.zc_responsible_person.slice(0, maxShowResponsiblePeopleInTable)"
                          >
                            <div
                              v-if="person && person.name"
                              :key="personId"
                            >
                              {{
                                person.name +
                                (
                                    person.email ? ' (' + person.email + ')' : ''
                                ) +
                                (
                                  personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1
                                    ? ', '
                                    : ''
                                )
                              }}
                            </div>
                          </template>
                          <div
                            v-if="item.zc_responsible_person.length > maxShowResponsiblePeopleInTable"
                          >
                            ...
                          </div>
                        </div>
                      </template>

<!--                      Tooltip content-->
                      <template
                        v-for="(person, personId) in item.zc_responsible_person"
                      >
                        <div
                          v-if="person && person.name"
                          :key="personId"
                        >
                          {{
                            person.name +
                            (
                                person.email ? ' (' + person.email + ')' : ''
                            ) +
                            (
                              personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1
                                ? ', '
                                : ''
                            )
                          }}
                        </div>
                      </template>
                    </v-tooltip>

<!--                    Otherwise, display all responsible people (limit is not exceeded)-->
                    <div v-else
                    >
                      <template
                        v-for="(person, personId) in item.zc_responsible_person"
                      >
                        <div
                          v-if="person && person.name"
                          :key="personId"
                        >
                          {{
                            person.name +
                            (
                                person.email ? ' (' + person.email + ')' : ''
                            ) +
                            (
                              personId !== undefined && personId !== null && personId !== item.zc_responsible_person.length - 1
                                ? ', '
                                : ''
                            )
                          }}
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else
                  >
                    -
                  </template>
                </template>

<!--                Status-->
                <template #[`item.status`]="{ item }">
                  {{ item && item.status && item.status.description ? $t(item.status.description) : '-' }}
                </template>

<!--                Actions-->
                <template
                  #[`item.actions`]="{ item }"
                >
                  <v-menu
                    offset-y
                    offset-overflow
                    left
                  >
                    <template #activator="{ on }">
                      <v-icon
                        v-on="on"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </template>

                    <v-list class="pa-0">
                      <v-list-item
                        class="d-flex align-center list-item-min-height"
                        link
                        @click="openEditDialog(item)"
                      >
                        <v-list-item-icon class="align-self-center my-0 mr-2">
                          <v-icon small>
                            mdi-pencil
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-title class="main-font-size">
                          {{ $t('base.edit') }}
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        class="d-flex align-center list-item-min-height"
                        link
                        @click="openDeleteDialog(item)"
                      >
                        <v-list-item-icon class="align-self-center my-0 mr-2">
                          <v-icon small>
                            mdi-delete
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-title class="main-font-size">
                          {{ $t('base.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>
      </pane>
    </splitpanes>
    <delete-prp-modal
      :open-modal="openDeleteOrderModal"
      :id="deleteId"
      @close-work-order-confirmed="deleteConfirmed"
      @close-work-order-canceled="openDeleteOrderModal = false"
    />
  </div>
</template>

<script>
import { debounce, forEach, isEmpty, isObject } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import PrpMap from '../components/PrpMap.vue'
import PrpControls from '../components/PrpControls.vue'
import DeletePrpModal from '../components/DeletePrpModal.vue'
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { api } from '@/global/services/api'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/prp')

const RESIZING_MAP_TIMEOUT_IN_MS = 200
const MAX_SHOW_RESPONSIBLE_PEOPLE_IN_TABLE = 1
const MAX_SHOW_END_DATES_IN_TABLE = 1
const TABLE_EXTENSIONS_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export default {
  name: 'PrpIndex',

  components: {
    PrpMap,
    PrpControls,
    Splitpanes,
    Pane,
    DeletePrpModal
  },

  data () {
    return {
      resizeMap: false,
      workOrder: false,
      WorkOrderDialog: false,
      tableVisible: false,
      firstPaneSize: 85,
      secondPaneSize: 15,
      toDateFromCreateOrUpdate: '',
      resetHistory: false,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['id'],
        sortDesc: [true],
        active: false,
        excavationOrderTypes: [],
        from: '',
        to: '',
        consent_number: '',
        address: '',
        year: ''
      },
      edit: false,
      editItem: {},
      openDeleteOrderModal: false,
      deleteId: 0,
      invokeGetIcons: false,
      clickedMapMarker: false,
      maxShowResponsiblePeopleInTable: MAX_SHOW_RESPONSIBLE_PEOPLE_IN_TABLE,
      maxShowEndDatesInTable: MAX_SHOW_END_DATES_IN_TABLE,
      tableExtensionsDatetimeFormat: TABLE_EXTENSIONS_DATE_TIME_FORMAT
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination']),

    header () {
      const data = []
      const columns = ['applicant', 'investor', 'geolocation', 'consent_number', 'start_date', 'end_date', 'zc_responsible_person', 'status']
      const translatedColumns = [
        this.$t('road-maintenance/prp.request_applicant'),
        this.$t('road-maintenance/dropdown-types.investor'),
        this.$t('road-maintenance/prp.construction_site_address'),
        this.$t('road-maintenance/prp.consent_number'),
        this.$t('road-maintenance/prp.installation_date'),
        this.$t('road-maintenance/prp.withdrawal_date'),
        this.$t('road-maintenance/prp.responsible_person_contact'),
        this.$t('road-maintenance/excavation-service.status')
      ]

      forEach(columns, function (column, key) {
        data.push({
          text: translatedColumns[key],
          value: column,
          align: 'center',
          sortable: !['zc_responsible_person', 'status'].includes(column),
          class: 'data-table-header'
        })
      })
      data.push({
        text: this.$t('base.actions'),
        value: 'actions',
        align: 'center',
        class: 'data-table-header',
        sortable: false
      })

      return data
    },

    active () {
      return this.$route.name === 'ExcavationActiveWorkOrders'
    },

    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    }
  },

  watch: {
    resizeMap: debounce(function () {
      this.$refs.excavationOrderMap.invalidateMapSize()
    }, RESIZING_MAP_TIMEOUT_IN_MS)
  },

  methods: {
    ...mapActions(['fetch']),

    getItems () {
      const params = {
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        sort: {
          by: this.options.sortBy[0] ? this.options.sortBy[0] : null,
          desc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
        },
        filters: {
          status: this.options.status,
          from: this.options.from,
          to: this.options.to,
          consent_number: this.options.consent_number,
          address: this.options.address,
          year: this.options.year
        }
      }

      this.fetch(params)
    },

    async pullData () {
      this.invokeGetIcons = true
      await this.getItems()
    },

    openEditDialogMap () {
      this.clickedMapMarker = true
    },

    closeEditDialog () {
      this.clickedMapMarker = false
      this.edit = false
      this.editItem = {}
    },

    formatSqlDateTime (date, format) {
      return formatSqlDateTime(date, format)
    },

    formatSqlDate (date) {
      return formatSqlDate(date)
    },

    async showTable () {
      if (!this.tableVisible) {
        await this.pullData()
        this.firstPaneSize = 40
        this.secondPaneSize = 60
        this.resizeMap = true
        this.tableVisible = true
      }
    },

    getOptions (val) {
      this.options.to = val.to
      this.options.from = val.from
      this.options.status = val.status
      this.options.consent_number = val.consentNumber
      this.options.address = val.address
      this.options.year = val.year
      this.options.page = 1
    },

    async openEditDialog (item) {
      try {
        const response = await api()['road-maintenance'].get('temporary-traffic-regulation/' + item.id)
        const { data } = response || {}
        this.edit = true
        if (data && isObject(data) && !isEmpty(data)) {
          this.editItem = {
            id: data?.id,
            address: data?.geolocation?.address,
            latitude: data?.geolocation?.latitude,
            longitude: data?.geolocation?.longitude,
            applicant: data?.applicant,
            investor: data?.investor,
            consent_number: data?.consent_number,
            start_date: data?.start_date,
            end_date: data?.end_date,
            status: data?.status?.name,
            zc_responsible_person: data?.zc_responsible_person,
            extensions: data?.extensions || [],
            record_pdfs: data?.record_pdfs,
            record_images: data?.record_images
          }
        }
        else {
          this.editItem = {}
        }
      }
      catch (e) {
        console.log(e)
      }
    },

    openDeleteDialog (item) {
      this.deleteId = item.id
      this.openDeleteOrderModal = true
    },

    async deleteConfirmed (id) {
      try {
        await api()['road-maintenance'].delete('temporary-traffic-regulation/' + id)
        this.openDeleteOrderModal = false
        await this.pullData()
      }
      catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cursor-default-style-on-hover {
  cursor: default;
}
</style>
