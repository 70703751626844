<template>
  <div>
    <div
      v-for="(attribute, index) in tab.columns"
      :key="index"
      class="mb-6"
    >
<!--      Text field-->
      <v-text-field
        v-if="!attribute.type"
        v-model="formDataCopy[index]"
        :label="attribute.label"
        :type="attribute.type"
        :error-messages="validationErrorsCopy[index]"
        hide-details="auto"
        filled
        clearable
        dense
      />

<!--      Address combobox-->
      <v-combobox
        v-if="attribute.type === 'address'"
        v-model="formDataCopy[index]"
        :loading="isLoading"
        :items="searchResults"
        :label="attribute.label"
        :search-input.sync="search"
        :error-messages="validationErrorsCopy[index]"
        hide-details="auto"
        item-text="label"
        :item-value="getCoordinates"
        hide-selected
        hide-no-data
        filled
        dense
        @change="onSelectedAddress"
        @input.native="onInput($event, index)"
      />

<!--      Autocomplete-->
      <v-autocomplete
        v-if="attribute.type === 'autocomplete'"
        v-model="formDataCopy[index]"
        :ref="autocompleteRefBaseValue + index"
        :label="attribute.label ? attribute.label : ''"
        :items="getDropdownItems(index, attribute)"
        :item-value="getAutocompleteValue(attribute)"
        :error-messages="validationErrorsCopy[index]"
        :item-text="item => getAutocompleteDisplayValue(item, attribute)"
        :return-object="!!attribute.returnObject"
        :multiple="attribute.multiple !== undefined ? attribute.multiple : false"
        :menu-props="{ bottom: true, offsetY: true, maxHeight: 250 }"
        :search-input="searchInput[index]"
        hide-details="auto"
        filled
        clearable
        @change="event => handleAutocompleteChangeEvent(event, index, attribute)"
        @update:search-input="value => onSearchInputUpdate(value, index)"
      />

<!--      Date picker-->
      <v-menu
        v-if="attribute.type === 'datepicker'"
        v-model="datePickerMenus[index]"
        transition="slide-y-transition"
        min-width="auto"
        :close-on-content-click="false"
      >
        <template #activator="{}">
          <div
            class="clickable-text-field"
          >
            <v-text-field
              v-model="formDataCopy[index]"
              :class="{ 'invalid-date-text': !!validationErrorsCopy[index] }"
              append-icon="mdi-calendar-month-outline"
              :label="attribute.label"
              :error-messages="validationErrorsCopy[index]"
              hide-details="auto"
              :style="getFieldStyle(attribute)"
              filled
              :dark="attribute.dark !== undefined ? attribute.dark : false"
              clearable
              @click:append="onDatePickerClick(index)"
              @input.native="onDateInput($event, index)"
            />
          </div>
        </template>
        <v-date-picker
          v-model="formDataCopy['date_' + index]"
          first-day-of-week="1"
          @dblclick:date="acceptChosenDate(index)"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="acceptChosenDate(index)"
          >
            {{ $t('base.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>

<!--      Datetime picker-->
      <div
        v-if="attribute.type === 'datetime-picker'"
      >
        <v-menu
          v-model="openedDateTimePickers[index]"
          transition="slide-y-transition"
          nudge-top="530"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
        >
          <template
            #activator="{}"
          >
            <v-text-field
              v-model="formDataCopy[index]"
              :label="attribute.label"
              :type="attribute.type"
              :error-messages="validationErrorsCopy[index]"
              append-icon="mdi-calendar-month-outline"
              hide-details="auto"
              :style="getFieldStyle(attribute)"
              :dark="attribute.dark !== undefined ? attribute.dark : false"
              filled
              clearable
              dense
              @click:append="openDateTimePicker(index)"
            />
          </template>
          <datetime-picker
            v-if="openedDateTimePickers[index]"
            v-model="formDataCopy[index]"
            :date-format="attribute && attribute.dateFormat ? attribute.dateFormat : null"
            :field="attribute"
            @close="closeDateTimePicker(index)"
            @reset-date="formDataCopy[index] = ''"
          />
        </v-menu>
      </div>
<!--      Checkbox-->
      <v-checkbox
        v-if="attribute.type === 'checkbox'"
        v-model="formDataCopy[index]"
        :label="attribute.label"
      />

<!--      Documents-->
      <div
        v-if="attribute.type === 'display_images'"
        style="position: relative;"
      >
        <span
          v-if="attribute.type === 'display_images'"
          style="font-weight: bold; font-size: 1rem;"
        >
          Fotografije
        </span>
        <v-tooltip
          v-if="uploadDocumentsCopy.images.length < 3"
          color="black"
          top
        >
          <template
            #activator="{ on, attrs }"
          >
            <v-btn
              style="height: 1.5rem; margin-bottom: 0.4rem;"
              class="primary ml-2"
              v-bind="attrs"
              v-on="on"
              @click="openFileInput('imageInput')"
            >
              <input
                ref="imageInput"
                type="file"
                enctype="multipart/form-data"
                multiple
                :accept="'image/*'"
                :max="3"
                hidden
                @change="selectedImages($event)"
              >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>Učitaj fotografije</span>
        </v-tooltip>
        <div
          v-if="uploadDocumentsCopy.images.length && !$vuetify.breakpoint.mobile"
          style="position: absolute; right: 0; margin-top: -34px;"
        >
          <v-btn
            class="error mr-3"
            style="color: white;"
            @click="uploadDocumentsCopy.images = []"
          >
            Ukloni sve
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn
            color="green"
            style="color: white;"
            @click="downloadAllImages"
          >
            Preuzmi sve
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-divider
          v-if="attribute.type === 'display_images'"
          class="mb-3"
        />
      </div>
      <div
        v-if="attribute.type === 'display_images'"
        class="image-row"
      >
        <div
          v-for="number in attribute.numberOfPlaceholders"
          :key="number"
        >
          <template
            v-if="number - 1 in uploadDocumentsCopy.images"
          >
            <!-- If uploaded image exists for this placeholder -->
            <v-img
              :src="uploadDocumentsCopy.images[number - 1].url"
              class="uploaded-image"
              alt="slika nedostupna"
            >
              <v-tooltip
                color="gray"
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <v-btn
                    small
                    class="primary"
                    style="position: absolute; top: 0; right: 0;"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadImage(uploadDocumentsCopy.images[number - 1])"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Preuzmi</span>
              </v-tooltip>
              <v-tooltip
                color="gray"
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <v-btn
                    small
                    class="error"
                    style="position: absolute; top: 0; right: 35%;"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteImage(number - 1)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Obriši</span>
              </v-tooltip>
            </v-img>
          </template>
          <template v-else>
            <!-- Placeholder if no uploaded image for this index -->
            <div
              class="mb-5 mr-3"
              style="width: 10rem; height: 10rem; border: 0.2rem dashed; text-align: center; align-content: center;"
            >
              Fotografija
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="attribute.type === 'display_pdf_files'"
        class="mt-4"
        style="position: relative;"
      >
        <span
          v-if="attribute.type === 'display_pdf_files'"
          style="font-weight: bold; font-size: 1rem;"
        >
          Pdf dokumenti
        </span>
        <v-tooltip
          v-if="uploadDocumentsCopy.pdfFiles.length < maximumAllowedPdfFiles"
          color="black"
          top
        >
          <template
            #activator="{ on, attrs }"
          >
            <v-btn
              style="height: 1.5rem; margin-bottom: 0.4rem;"
              class="primary ml-2"
              v-bind="attrs"
              v-on="on"
              @click="openFileInput('pdfInput')"
            >
              <input
                ref="pdfInput"
                type="file"
                enctype="multipart/form-data"
                multiple
                :accept="pdfFileType"
                :max="2"
                hidden
                @change="selectedPdfFiles($event)"
              >
              <v-icon
                style="font-size: 1.5rem;"
              >
                mdi-upload
              </v-icon>
            </v-btn>
          </template>
          <span>Učitaj pdf dokumenta</span>
        </v-tooltip>
        <div
          v-if="uploadDocumentsCopy.pdfFiles.length && !$vuetify.breakpoint.mobile"
          style="position: absolute; right: 0; margin-top: -34px;"
        >
          <v-btn
            class="error mr-3"
            style="color: white;"
            @click="uploadDocumentsCopy.pdfFiles = []"
          >
            Ukloni sve
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn
            color="green"
            style="color: white;"
            @click="downloadAllPdfFiles"
          >
            Preuzmi sve
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-divider
          v-if="attribute.type === 'display_pdf_files'"
          class="mb-3"
        />
      </div>
      <div
        v-if="attribute.type === 'display_pdf_files'"
      >
        <div
          v-for="number in attribute.numberOfPlaceholders"
          :key="number"
        >
          <template
            v-if="number - 1 in uploadDocumentsCopy.pdfFiles"
          >
            <div
              style="position: relative; align-items: center; background: transparent;"
              class="mb-5 mt-5"
            >
              <v-tooltip
                color="gray"
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <div
                    class="pdf-info"
                    @click="downloadPdf(uploadDocumentsCopy.pdfFiles[number - 1])"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      style="font-size: 2rem;"
                      color="red"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <span
                      style="color: black; font-weight: bold; overflow: hidden;"
                      :style="{ width: $vuetify.breakpoint.mobile ? '64%' : '100%' }"
                    >
                      {{ uploadDocumentsCopy.pdfFiles[number - 1].name }}
                    </span>
                  </div>
                </template>
                <span>Preuzmi</span>
              </v-tooltip>
              <v-tooltip
                color="gray"
                top
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <v-icon
                    class="delete-pdf-icon"
                    v-bind="attrs"
                    v-on="on"
                    @click="deletePdf(number - 1)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Ukloni</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-else
          >
            <div
              class="mb-5 mr-3"
              style="width: 10rem; height: 3rem; border: 0.2rem dashed; text-align: center; align-content: center;"
            >
              PDF
            </div>
          </template>
        </div>
      </div>

<!--      Button for displaying location modal and location text field for displaying selected coordinates-->
      <v-btn
        v-if="attribute.type === 'modal'"
        class="mb-6"
        @click="openLocationModal(index)"
      >
        {{ $t('road-maintenance.excavation_modal_location_btn') }}
      </v-btn>
      <v-text-field
          v-if="attribute.type === 'modal'"
          v-model="formDataCopy[index]"
          :label="$t('road-maintenance/prp.chosen_address_coordinates')"
          hide-details="auto"
          :error-messages="validationErrorsCopy[index]"
          filled
          dense
          readonly
      />

<!--      Log data title-->
      <span
        v-if="attribute.type === 'logData' && attribute.itemKey && formDataCopy[index] && formDataCopy[index].length"
        class="logDataTitle"
      >
        {{ attribute.title ? attribute.title : '' }}
      </span>

<!--      Log data-->
      <div
        v-if="attribute.type === 'logData' && formDataCopy[index] && formDataCopy[index].length"
        class="logDataWrapper"
      >
        <template
          v-for="(logItem, logItemIndex) in formDataCopy[index]"
        >
          <v-tooltip
            v-if="attribute.hoverKey && logItem[attribute.itemKey]"
            :key="logItemIndex"
            top
            color="black"
          >
            <template
              #activator="{ on, attrs }"
            >
              <div
                v-if="logItem[attribute.itemKey]"
                :key="logItemIndex"
                v-on="on"
                v-bind="attrs"
                class="logItem"
              >
                {{ getLogItemData(attribute, logItem[attribute.itemKey]) }}
              </div>
            </template>
            <span>
              {{ logItem[attribute.hoverKey] }}
            </span>
          </v-tooltip>
          <div
            v-else-if="logItem[attribute.itemKey]"
            :key="logItemIndex"
            class="logItem"
          >
            {{ logItem[attribute.itemKey] }}
          </div>
        </template>
      </div>
    </div>
    <LocationMapModal
      v-if="showLocationModal"
      :location-dialog="showLocationModal"
      :editAddress="editCoordinates"
      :dialog-title="$t('base/patrol-vehicle.tips_form_location_modal_title')"
      :index="indexForMap"
      :mapClass="mapClass"
      @clicked-address="getClickedAddress"
      @close-dialog="closeLocationMapModal"
    />
  </div>
</template>

<script>
import LocationMapModal from './LocationMapModal.vue'
import { api } from '@/global/services/api'
import dayjs from 'dayjs'
import { isEmpty, isObject } from 'lodash'
import { convertToBase64 } from '@/global/services/helpers/files'
import { validateDateTimeFormat, convertDate } from '@/global/services/helpers/dates'
import DatetimePicker from '@/global/components/view-layouts/tabs-layout/components/fields/DatetimePicker.vue'

const DEFAULT_AUTOCOMPLETE_VALUE = 'id'
const DEFAULT_AUTOCOMPLETE_TEXT = 'name'
const DATE_PICKER_FORMAT = 'YYYY-MM-DD'
const AUTOCOMPLETE_REF_BASE_VALUE = 'autocomplete_'
const SEARCH_TIMEOUT_IN_MS = 500
const IMAGE_FILE_TYPE = 'image/jpeg'
const PDF_FILE_TYPE = 'application/pdf'
export default {
  name: 'PrpFields',

  components: {
    DatetimePicker,
    LocationMapModal
  },

  props: {
    tab: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editAddress: {
      type: Object,
      default: () => ({})
    },
    userInput: {
      type: String,
      default: ''
    },
    resetLocationModalOpening: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      required: true
    },
    confirmedCoordinates: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: true
    },
    dropDownValues: {
      type: Object,
      default: () => ({})
    },
    mapClass: {
      type: String,
      default: 'prp-location-map-modal'
    },
    uploadDocuments: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showLocationModal: false,
      indexForMap: '',
      datePickerMenus: [],
      searchResults: [],
      search: null,
      inputTimeoutId: null,
      isLoading: false,
      editCoordinates: {},
      userInputCopy: '',
      maxSizePerImageInPixels: 1024,
      initialLoad: true,
      maximumAllowedPdfFiles: 2,
      maximumAllowedImages: 3,
      maximumMBPerResizedImage: 3,
      autocompleteRefBaseValue: AUTOCOMPLETE_REF_BASE_VALUE,
      pdfFileType: PDF_FILE_TYPE,
      searchInput: {},
      openedDateTimePickers: []
    }
  },

  computed: {
    formDataCopy: {
      get () {
        return this.formData
      },
      set (val) {
        return val
      }
    },
    uploadDocumentsCopy: {
      get () {
        return this.uploadDocuments
      },
      set (val) {
        return val
      }
    },
    validationErrorsCopy: {
      get () {
        return this.validationErrors
      },
      set (val) {
        return val
      }
    },
    confirmedCoordinatesCopy: {
      get () {
        return this.confirmedCoordinates
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    formDataCopy: {
      deep: true,
      handler (newData) {
        this.$emit('update-data', newData)
      }
    },
    uploadDocumentsCopy: {
      deep: true,
      handler (newDocuments) {
        this.$emit('update-documents', newDocuments)
      }
    },
    userInputCopy: {
      deep: true,
      handler (val) {
        this.$emit('update-user-input', val)
      }
    },
    resetLocationModalOpening: {
      deep: true,
      handler (val) {
        this.showLocationModal = false
      }
    },
    validationErrorsCopy: {
      deep: true,
      handler (newErrors) {
        this.$emit('update-errors', newErrors)
      }
    },
    confirmedCoordinatesCopy: {
      handler (val) {
        this.$emit('update-confirmed-coordinates', val)
      }
    },
    search: {
      handler (val) {
        if (!this.showLocationModal && !this.initialLoad) {
          this.debounceLogic(async () => {
            this.isLoading = true
            this.searchResults = []
            if (val && val !== '') {
              await this.searchAddress(val)
            }
            else {
              this.isLoading = false
            }
          })
        }
        else {
          this.initialLoad = false
        }
      }
    }
  },

  async created () {
    this.userInputCopy = this.userInput
  },

  methods: {
    isEmpty,

    openDateTimePicker (index) {
      this.$set(this.openedDateTimePickers, index, true)
    },

    closeDateTimePicker (index) {
      this.$set(this.openedDateTimePickers, index, false)
    },

    onSearchInputUpdate (value, index) {
      this.$set(this.searchInput, index, '')
    },

    handleAutocompleteChangeEvent (event, index, field) {
      const { keepInput } = field || null

      if (keepInput === false) {
        this.searchInput = {}
      }

      this.$delete(this.validationErrorsCopy, index)
      if (this.$refs[this.autocompleteRefBaseValue + index] && this.$refs[this.autocompleteRefBaseValue + index][0]) {
        this.$refs[this.autocompleteRefBaseValue + index][0].setMenuIndex(event)
      }
    },

    debounceLogic (fn, time = SEARCH_TIMEOUT_IN_MS) {
      if (fn && typeof fn === 'function') {
        clearTimeout(this.inputTimeoutId)

        this.inputTimeoutId = setTimeout(async () => {
          await fn()
        }, time)
      }
    },

    getLogItemData (field, data) {
      if (!data) {
        return '-'
      }

      const { custom_display_value: customDisplayValue } = field || {}

      if (customDisplayValue && typeof customDisplayValue === 'function') {
        return customDisplayValue(data)
      }

      return data
    },

    getFieldStyle (field) {
      const { style } = field || {}
      let customFieldStyle = {}

      if (style && isObject(style)) {
        customFieldStyle = {
          ...customFieldStyle,
          ...style
        }
      }

      return customFieldStyle
    },

    getAutocompleteValue (field) {
      const { form_value: formValue } = field?.autocomplete_options || {}

      return formValue || DEFAULT_AUTOCOMPLETE_VALUE
    },

    getAutocompleteDisplayValue (item, field) {
      const { custom_display_value: customDisplayValue } = field?.autocomplete_options || {}

      if (customDisplayValue && typeof customDisplayValue === 'function') {
        return customDisplayValue(item)
      }

      return item && item[DEFAULT_AUTOCOMPLETE_TEXT] ? item[DEFAULT_AUTOCOMPLETE_TEXT] : null
    },

    getDropdownItems (index, field) {
      const { items, defaultValue } = field || {}

      if (items && items.length) {
        if (defaultValue && !this.editMode) {
          this.$set(this.formDataCopy, index, defaultValue)
        }

        return items
      }

      return this.dropDownValues && this.dropDownValues[index] && this.dropDownValues[index].length
        ? this.dropDownValues[index]
        : []
    },

    onDatePickerClick (fieldKey) {
      // Get fields date format from field config
      const fieldDateFormat = this.getFieldsDateFormat(fieldKey)

      // If text field is empty (date is not entered manually), before opening datepicker set current date for the datepicker and show them
      // Check is entered date valid before opening datepicker
      if (!this.formDataCopy[fieldKey] || !validateDateTimeFormat(this.formDataCopy[fieldKey], fieldDateFormat)) {
        this.$set(this.formDataCopy, 'date_' + fieldKey, dayjs().format(DATE_PICKER_FORMAT))
        this.$set(this.datePickerMenus, fieldKey, true)
        return
      }

      // Convert date format to datepicker known format (YYYY-MM-DD)
      const datePickerFormatted = convertDate(this.formDataCopy[fieldKey], fieldDateFormat, DATE_PICKER_FORMAT)

      // Set date value for datepicker
      this.$set(this.formDataCopy, 'date_' + fieldKey, datePickerFormatted)

      // Open date picker
      this.$set(this.datePickerMenus, fieldKey, true)
    },

    getFieldsDateFormat (fieldKey) {
      const { columns } = this.tab || {}
      let finalFormat = DATE_PICKER_FORMAT

      if (!fieldKey || !columns) {
        return finalFormat
      }

      if (columns[fieldKey]) {
        const { format } = columns[fieldKey]

        finalFormat = format && typeof format === 'string' ? format : DATE_PICKER_FORMAT
      }

      return finalFormat
    },

    openFileInput (type) {
      this.$refs[type][0].click()
      this.$refs[type][0].value = ''
    },

    selectedImages (event) {
      try {
        // Close file dialog if any of these props are unset
        if (!event || !event.target || !event.target.files) {
          alert('Dogodila se pogreška prilikom učitavanja slika, molimo pokušajte ponovo')
          return
        }

        // If there is no selected files, close file dialog
        if (!event.target.files.length) {
          return
        }

        const selectedFiles = event.target.files
        const remainingSlots = this.maximumAllowedImages - this.uploadDocumentsCopy.images.length

        // Checking whether the number of uploaded files exceeds the limit
        if (selectedFiles.length > this.maximumAllowedImages || remainingSlots < selectedFiles.length) {
          alert('Možete učitati maksimalno 3 fotografije!')
          return
        }

        for (let i = 0; i < Math.min(selectedFiles.length, remainingSlots); i++) {
          // Resize uploaded image
          this.resizeImage(selectedFiles[i], this.maxSizePerImageInPixels).then(({ dataUrl, sizeInMB }) => {
            const imageName = selectedFiles[i].name

            // Get image name without extension
            const lastDotIndex = imageName.lastIndexOf('.')
            let imageNameWithoutExtension = imageName
            if (lastDotIndex !== -1) {
              imageNameWithoutExtension = imageName.substring(0, lastDotIndex)
            }

            // Checking whether the resized image size exceeds allowed size
            if (sizeInMB > this.maximumMBPerResizedImage) {
              alert(`Veličina slike: ${imageNameWithoutExtension} je prevelika, molimo odaberite sliku manje veličine`)
              return null
            }

            return {
              name: imageNameWithoutExtension,
              url: dataUrl,
              size: sizeInMB
            }
          }).then(imageData => {
            if (imageData) {
              this.uploadDocumentsCopy.images.push(imageData)
            }
          }).catch((error) => {
            console.error('Error occurred during resize images process, exception: ' + error)
          })
        }
      }
      catch (error) {
        alert('Dogodila se pogreška prilikom učitavanja slika, molimo pokušajte ponovo')
        console.error('Error occurred during upload images process, exception: ', error)
      }
    },

    resizeImage (file, maxSize) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.createElement('canvas')

      const resize = () => {
        let { width, height } = image

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        }
        else if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }

        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        const dataUrl = canvas.toDataURL(IMAGE_FILE_TYPE)
        const bytes = dataUrl.length * 0.75
        const megabytes = bytes / (1024 * 1024)

        return { dataUrl, sizeInMB: megabytes }
      }

      return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
          reject(new Error('Not an image'))
          return
        }

        reader.onload = (readerEvent) => {
          image.onload = () => {
            const { dataUrl, sizeInMB } = resize()
            resolve({ dataUrl, sizeInMB })
          }
          image.src = readerEvent.target.result
        }

        reader.readAsDataURL(file)
      })
    },

    async deleteImage (index) {
      this.$delete(this.uploadDocumentsCopy.images, index)
    },

    downloadImage (image) {
      const link = document.createElement('a')
      link.href = image.url
      link.download = image.name
      link.click()
    },

    selectedPdfFiles (event) {
      const pdfFiles = event.target.files

      if (pdfFiles.length && (pdfFiles.length > this.maximumAllowedPdfFiles || this.maximumAllowedPdfFiles - this.uploadDocumentsCopy.pdfFiles.length < pdfFiles.length)) {
        alert('Možete učitati maksimalno 2 pdf fajla!')
        return
      }

      for (let i = 0; i < pdfFiles.length; i++) {
        convertToBase64(pdfFiles[i]).then(base64Content => {
          this.uploadDocumentsCopy.pdfFiles.push({
            name: pdfFiles[i].name, // For frontend purpose
            content: base64Content
          })
        })
      }
    },

    deletePdf (index) {
      this.$delete(this.uploadDocumentsCopy.pdfFiles, index)
    },

    downloadPdf (pdfFile) {
      const downloadLink = document.createElement('a')
      downloadLink.href = pdfFile.content
      downloadLink.download = pdfFile.name
      downloadLink.click()
    },

    downloadAllImages () {
      if (this.uploadDocumentsCopy.images && this.uploadDocumentsCopy.images.length) {
        this.uploadDocumentsCopy.images.map(image => {
          const link = document.createElement('a')
          link.href = image.url
          link.download = image.name
          link.click()
        })
      }
    },

    downloadAllPdfFiles () {
      if (this.uploadDocumentsCopy.pdfFiles && this.uploadDocumentsCopy.pdfFiles.length) {
        this.uploadDocumentsCopy.pdfFiles.map(pdfFile => {
          const link = document.createElement('a')
          link.href = pdfFile.content
          link.download = pdfFile.name
          link.click()
        })
      }
    },

    onInput (event, index) {
      this.$delete(this.validationErrorsCopy, index)
      this.userInputCopy = event.target.value
      this.$set(this.formDataCopy, 'address', event.target.value)
    },

    onSelectedAddress (address) {
      const that = this
      if (this.formDataCopy.address && this.formDataCopy.address.label && this.userInputCopy !== this.formDataCopy.address.label) {
        this.userInputCopy = this.userInput
      }
      setTimeout(() => {
        if (that.userInputCopy !== '') {
          that.$set(that.formDataCopy, 'address', that.userInputCopy)
        }
      }, 100)
      if (address && address !== '' && address.x && address.y) {
        that.editCoordinates = address
        that.indexForMap = 'location'
        that.showLocationModal = true
      }
    },

    getCoordinates (item) {
      return { x: item.x, y: item.y, label: item.label }
    },

    async searchAddress (val) {
      try {
        this.searchResults = await api()['satellite-tracking'].get(`map-search?mapType=here&q=${val}`)
        this.isLoading = false
      }
      catch (e) {
        this.isLoading = false
        this.searchResults = []
        console.log(e)
      }
    },

    openLocationModal (index) {
      if (this.formDataCopy.location && this.formDataCopy.location !== '') {
        this.editCoordinates = { y: this.formDataCopy.location.split(' ')[0], x: this.formDataCopy.location.split(' ')[1] }
      }
      this.$emit('update-location-modal-open-status', true)
      this.indexForMap = index
      this.showLocationModal = true
    },

    onDateInput (event, index) {
      this.debounceLogic(() => {
        const fieldsDateFormat = this.getFieldsDateFormat(index)
        const inputDate = event.target.value

        if (inputDate) {
          if (validateDateTimeFormat(inputDate, fieldsDateFormat)) {
            this.$delete(this.validationErrorsCopy, index)
          }
          else {
            this.$set(this.validationErrorsCopy, index, this.$t('base.invalid_date').replace(':format', fieldsDateFormat))
          }
        }
        else {
          this.$delete(this.validationErrorsCopy, index)
        }
      })
    },

    acceptChosenDate (index) {
      if (this.formDataCopy['date_' + index] && this.formDataCopy['date_' + index] !== '') {
        const fieldsDateFormat = this.getFieldsDateFormat(index)

        this.$delete(this.validationErrors, index)
        this.formDataCopy[index] = convertDate(this.formDataCopy['date_' + index], DATE_PICKER_FORMAT, fieldsDateFormat)
      }
      this.datePickerMenus[index] = false
    },

    closeLocationMapModal () {
      this.showLocationModal = false
      this.searchResults = []
      this.$emit('update-location-modal-open-status', false)
    },

    async getClickedAddress (address) {
      if (this.indexForMap !== '' && address && address.length !== 0 && address[0].lat && address[0].lon) {
        this.$set(this.confirmedCoordinatesCopy, 'x', address[0]?.lon)
        this.$set(this.confirmedCoordinatesCopy, 'y', address[0]?.lat)
        this.$set(this.formDataCopy, this.indexForMap, `${address[0]?.lat} ${address[0]?.lon}`)
        this.searchResults = []
      }
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep {
  .invalid-date-text > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    color: red !important;
  }

  .invalid-date-text .v-text-field__slot .theme--dark.v-label--active {
    color: red !important;
  }

  .invalid-date-text .v-text-field__details .v-messages.theme--dark .v-messages__wrapper .v-messages__message {
    color: red !important;
  }
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.uploaded-image {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin: 0 1rem 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative; /* Make the container position relative */
}

.delete-button {
  position: absolute;
  top: -0.5rem;
  right: -0.2rem;
  background-color: lightgray;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
}

.pdf-info {
  display: flex;
  align-items: center;
  margin: 0 1rem 0.5rem 0;
  background-color: #D8D9DA;
  border-radius: 10px;
  padding: 0.4rem;
  border-bottom: 0.1rem solid black;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.pdf-info:hover {
  box-shadow: 0 4px 8px #5c5656;
}

.delete-pdf-icon {
  background: transparent;
  position: absolute;
  top: 0.3rem;
  right: 1.3rem;
  font-size: 2rem;
  color: red;
}

.delete-pdf-icon:hover {
  background: transparent;
  box-shadow: 0 4px 8px red;
  transition: box-shadow 0.3s ease;
}

.logDataWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #a6a1a1;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.logItem {
  position: relative;
  margin: 7px;
  background-color: #a6a1a1;
  border-radius: 10px;
  padding: 5px;
  min-width: 100px;
  text-align: center;
  color: #FFFFFF;
}

.logDataTitle {
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.logItem:hover {
  cursor: default;
}
</style>
