<template>
  <table-index
    :config="tableConfig"
    :create-config="createDetailsConfig"
  >
    <template
      #dynamic-content
    >
      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </table-index>
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'
import columnsContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue'
import { api } from '@/global/services/api'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'

const LAST_BREADCRUMB_KEY = 'org_unit'
export default {
  name: 'OrganizationalUnitView',

  components: {
    InformationDialog,
    TableIndex
  },

  data () {
    return {
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      },
      tableBreadcrumbs: {
        items: [
          {
            key: 'fleet',
            text: this.$t('fleet.fleet')
          },
          {
            key: 'settings',
            text: this.$t('fleet.settings')
          },
          {
            key: LAST_BREADCRUMB_KEY,
            text: this.$t('fleet.organizational_unit'),
            bolded: true
          }
        ]
      }
    }
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'vehicle-organizational-unit',
              method: 'get'
            }
          },
          rowClick: (event) => this.showOrganizationalUnitDetails(event),
          backFromTableDetailsView: () => this.backFromTableDetailsView(),
          search_and_buttons_config: {
            newClick: (event) => this.showCreateNewOrganizationalUnit(event)
          },
          filters_and_columns_visibility_config: {
            showRefreshButton: false,
            showFiltersButton: false,
            showColumnsVisibilityButton: false
          },
          title_and_back_config: {
            title: this.$t('fleet.organizational_unit')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            cellsConfig: {
              id: {
                style: {
                  textAlign: 'center'
                }
              }
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.organizational_unit_number'),
                key: 'id',
                style: {
                  width: '100px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle_document.name'),
                key: 'name',
                style: {
                  width: '200px'
                },
                always_visible: true
              }
            ],
            actionsConfig: [
              {
                label: this.$t('base.edit'),
                icon: 'mdi-pencil-box',
                click: (event) => this.showOrganizationalUnitDetails(event)
              },
              {
                label: this.$t('base.delete'),
                icon: 'mdi-delete',
                click: (event) => this.onOrganizationalUnitDelete(event)
              }
            ]
          }
        },
        breadcrumbs: this.tableBreadcrumbs
      }
    },

    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'vehicle-organizational-unit',
                method: 'post'
              }
            },
            afterSave: (event) => this.redirectToTable(event),
            tabContent: [
              {
                title: this.$t('fleet/vehicle.basic_info'),
                component: columnsContent,
                showButtons: true,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    fields: [
                      {
                        key: 'name',
                        label: this.$t('fleet/vehicle_document.name'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.fleet')
            },
            {
              text: this.$t('fleet.settings')
            },
            {
              text: this.$t('fleet/documents/prl_cargo.creating_organizational_unit'),
              bolded: true
            }
          ]
        }
      }
    },

    detailsConfig () {
      return {
        title: this.$t('fleet/vehicle.basic_info'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              const { id } = data || {}
              return id ? `vehicle-organizational-unit/${id}` : ''
            },
            method: 'post'
          }
        },
        tabContent: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            type: 'columns',
            showButtons: true,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                fields: [
                  {
                    key: 'name',
                    label: this.$t('fleet/vehicle_document.name'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    showOrganizationalUnitDetails (event) {
      const { index, instance, data } = event

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.detailsConfig)
        // Add organizational unit name to the breadcrumbs part at the last position and bold it
        const { name } = data || null

        if (name) {
          this.tableBreadcrumbs.items[this.tableBreadcrumbs.items.length - 1].bolded = false
          this.tableBreadcrumbs.items.push({
            text: name,
            bolded: true
          })
        }
      }
    },

    redirectToTable (event) {
      const { cancelTabSave } = event || null

      if (typeof cancelTabSave === 'function') {
        cancelTabSave()
      }
    },

    showCreateNewOrganizationalUnit (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage()
      }
    },

    async onOrganizationalUnitDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/settings/organizational_unit.delete_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/settings/organizational_unit.delete_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.deleteOrganizationalUnitCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async deleteOrganizationalUnitCallback (event) {
      try {
        const { id } = event?.data || null
        const { fetchTableData } = event || {}

        if (id) {
          // Delete chosen organizational unit
          await api().fleet.delete(`vehicle-organizational-unit/${id}`)

          // Close information popup
          this.$set(this.informationDialogData, 'show', false)

          // Refresh table
          if (typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    backFromTableDetailsView () {
      // When user came back from table details, remove lastly added organizational unit from the breadcrumbs
      if (this.tableBreadcrumbs.items[this.tableBreadcrumbs.items.length - 1].key !== LAST_BREADCRUMB_KEY) {
        this.tableBreadcrumbs.items.pop()
        this.tableBreadcrumbs.items[this.tableBreadcrumbs.items.length - 1].bolded = true
      }
    },

    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    }
  }
}
</script>
