import { api } from '@/global/services/api'
import common from '../common'

export default {
  name: 'tiresConfig',

  data () {
    return {
      tiresPermissions: {}
    }
  },

  mixins: [
    common
  ],

  computed: {
    tires () {
      return {
        title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
        key: 'tires',
        tabContent: [
          {
            title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
            component: this.tableGridContentComponent,
            rowClick: (event) => this.onShowTireDetailsPage(event, 'showTableDetailsPage'),
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/${this.$route.params.id}/tire`,
                method: 'get'
              }
            },
            permissions: this.tiresPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              const { status } = data || null
              this.currentStatusId = status
              this.$set(this.tiresPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
              this.$set(this.tiresPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
            },
            search_and_buttons_config: {
              additional_buttons: [
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                  prependIcon: 'mdi-file-pdf-box',
                  click: () => this.onTiresViewPdf()
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.change_status'),
                  prependIcon: 'mdi-swap-horizontal',
                  visible: (event) => {
                    const { permissions } = event || {}

                    return permissions && permissions.canChangeStatus
                  },
                  click: (event) => this.onChangeStatus(event, 'tires')
                }
              ],
              newClick: (event) => this.onShowNewTirePage(event, 'showTableNewPage')
            },
            title_and_back_config: {
              title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
              showBackButton: {
                uiViewName: 'PrlCargo'
              }
            },
            filters_and_columns_visibility_config: {
              showRefreshButton: false,
              showFiltersButton: false,
              showColumnsVisibilityButton: false
            },
            tableConfig: {
              styles: {
                useFixedHeaderWidth: true,
                dataRowHeight: '64px',
                headersHeight: '60px'
              },
              headers: [
                {
                  label: this.$t('fleet/documents/prl_cargo.sr_no'),
                  key: 'id',
                  style: {
                    width: '80px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.brand'),
                  key: 'brand',
                  style: {
                    width: '180px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.factory_number'),
                  key: 'factory_number',
                  style: {
                    width: '180px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.dimension'),
                  key: 'dimension',
                  style: {
                    width: '180px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.km'),
                  key: 'distance',
                  style: {
                    width: '180px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.tires_condition'),
                  key: 'odometer',
                  style: {
                    width: '328px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.comment'),
                  key: 'comment',
                  style: {
                    width: '328px'
                  },
                  always_visible: true
                }
              ],
              actionsConfig: [
                {
                  label: this.$t('base.edit'),
                  icon: 'mdi-pencil-box',
                  click: (event) => this.onShowTireDetailsPage(event, 'showTableDetailsPage')
                },
                {
                  label: this.$t('base.delete'),
                  icon: 'mdi-delete',
                  disabled: (event) => {
                    const { permissions } = event || {}

                    return permissions && !permissions.canEdit
                  },
                  click: (event) => this.onTireDelete(event)
                }
              ]
            }
          }
        ]
      }
    },

    tiresDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/tire`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/tire/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        fileKeys: [
          'signature_image'
        ],
        tabContent: [
          {
            type: 'columns',
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'brand',
                    label: this.$t('fleet/documents/prl_cargo.brand'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'factory_number',
                    label: this.$t('fleet/documents/prl_cargo.factory_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'dimension',
                    label: this.$t('fleet/documents/prl_cargo.dimension'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'distance',
                    label: this.$t('fleet/documents/prl_cargo.km'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'odometer',
                    label: this.$t('fleet/documents/prl_cargo.tires_condition'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'comment',
                    label: this.$t('fleet/documents/prl_cargo.comment'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onShowTireDetailsPage (event, functionName) {
      const { index, instance } = event

      if (instance && functionName && typeof instance[functionName] === 'function') {
        instance[functionName](index, this.tiresDetails)
      }
    },

    onShowNewTirePage (event, functionName) {
      if (functionName && event && typeof event[functionName] === 'function') {
        event[functionName](this.tiresDetails)
      }
    },

    async onTiresViewPdf () {
      await this.exportData(this.$route.params.id)
    },

    async onTireDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('base.delete_data_popup_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('base.delete_data_popup_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onTireDeleteCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async onTireDeleteCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}
        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/tire/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchTableData && typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    }
  }
}
