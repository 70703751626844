<template>
  <v-container fluid>
    <v-data-table
      v-model="selectedTrips"
      :headers="tripsConfig"
      :items="trips"
      item-key="key"
      show-select
      class="tracking-history-table fill-height overflow-y-auto"
      :item-class="itemRowBackground"
      @update:items-per-page="setItemsPerPage"
      @toggle-select-all="selectAllToggle"
    >
      <!-- eslint-disable vue/valid-v-slot -->
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-checkbox
          :value="isSelected"
          :readonly="item.disabled"
          :disabled="item.disabled"
          :ripple="false"
          @change="event =>select(!!event)"
        />
      </template>
      <template #top>
        <v-container
          fluid
          class="trip-table-heading pt-0"
        >
          <slot name="expand_button" />
          <v-row
            class="align-center flex-sm-nowrap pa-2"
            :class="[tripsLoaded ? 'justify-space-between' : 'justify-end']"
          >
            <div
              v-if="tripsLoaded"
              class="text-h5 flex-grow-1 flex-shrink-1"
            >
              {{ tableTitle }}
            </div>
            <slot name="picker_activator" />
          </v-row>
        </v-container>
      </template>
      <!-- eslint-disable vue/valid-v-slot -->
      <template #item.person.name="{ item }">
        <div
          v-if="checkUnassigned(item)"
          class="d-flex align-center"
        >
          <p class="mb-0 mr-2">
            {{ item.person.label }}
          </p>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-card-account-details
              </v-icon>
            </template>
            <span>{{ item.person.id_card_value }}</span>
          </v-tooltip>
        </div>
        <span v-else>{{ getPersonName(item) }}</span>
      </template>
      <template #item.startAddress="{ item }">
        <reverse-geocoding-field
          :key="`reverse-geocoding-field-${item.key}`"
          :address="item.startAddress"
          :lat-lng="{ lat: item.startLat, lng: item.startLng }"
          :vehicle-id="item.vehicle.id"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
      </template>
      <template #item.endAddress="{ item }">
        <reverse-geocoding-field
          :key="`reverse-geocoding-field-${item.key}`"
          :address="item.endAddress"
          :lat-lng="{ lat: item.endLat, lng: item.endLng }"
          :vehicle-id="item.vehicle.id"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import ReverseGeocodingField from '@/global/components/geocoding/ReverseGeocodingField'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'TripListTable',

  components: {
    ReverseGeocodingField
  },

  props: {
    selection: {
      type: Object,
      default: () => ({})
    },
    tripsLoaded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      itemsPerPage: 10
    }
  },

  computed: {
    ...mapGetters([
      'trips',
      'tripsConfig',
      'getSelectedTrips'
    ]),

    selectedTrips: {
      get () {
        return this.getSelectedTrips
      },
      set (value) {
        let trips = []
        trips = value.filter(function (trip) {
          return !trip.disabled
        })
        this.$emit('selection-trips', trips)
        this.setSelectedTrips(trips)
      }
    },

    vehicles () {
      return this.selection.vehicles
    },

    from () {
      return this.selection.from
    },

    to () {
      return this.selection.to
    },

    tableTitle () {
      if (this.vehicles) {
        return this.$t('satellite-tracking/history.trip_list_title')
          .replace(':vehicles', this.vehicles.map(vehicle => vehicle.registration).join(', '))
          .replace(':from', formatSqlDateTime(this.from, 'DD.MM.YYYY HH:mm'))
          .replace(':to', formatSqlDateTime(this.to, 'DD.MM.YYYY HH:mm'))
      }
      return ''
    }
  },

  mounted () {
    if (this.selectedTrips.length) this.setSelectedTrips([])
    if (this.trips.length) this.resetTrips()
  },

  methods: {
    ...mapActions([
      'setSelectedTrips',
      'resetTrips'
    ]),

    selectAllToggle (props) {
      const trips = props.items.filter(function (trip) {
        return !trip.disabled
      })

      if ((this.itemsPerPage === -1 || props.items.length <= this.itemsPerPage) && trips.length === this.selectedTrips.length) {
        this.setSelectedTrips([])
        return
      }
      this.setSelectedTrips(trips)
    },

    formatSqlDateTime (date) {
      return formatSqlDateTime(date)
    },

    checkUnassigned (item) {
      return item.person?.unassigned
    },

    getPersonName (item) {
      return item.person?.name
    },

    itemRowBackground: function (item) {
      return item.disabled ? 'disabled-trip' : ''
    },

    setItemsPerPage (val) {
      this.itemsPerPage = val
    }
  }
}
</script>

<style scoped>
.trip-table-heading {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.disabled-trip {
  color: #888888;
}

::v-deep {
  .mdi-checkbox-marked {
    color: rgba(0, 0, 0, 0.55) !important;
  }
}
</style>
