import { latLng } from 'leaflet'
import config from '@/modules/base-module/config/stores/config'

const defaultZagrebCenter = latLng(45.80983713304906, 15.986897825540488)

const defaultEuropeCenterAndZoom =
  {
    defaultEuropeCenter: latLng(57, 23),
    defaultZoom: 4
  }

const defaultMapOptions = {
  zoom: 7,
  zoomControl: false,
  center: defaultZagrebCenter,
  maxZoom: 19
}

const defaultMapSetup = {
  minZoom: 3,
  maxZoom: 19,
  maxNativeZoom: 18
}

const configState = config.state

const companyScopeGeolocation = () => {
  try {
    if (configState &&
      configState.companyScope &&
      configState.companyScope.company &&
      configState.companyScope.company.geolocation &&
      configState.companyScope.company.geolocation.length &&
      configState.companyScope.company.geolocation[0]
    ) {
      return latLng(configState.companyScope?.company?.geolocation[0])
    }
    else {
      return null
    }
  }
  catch (e) {
    console.log('Error occurred while trying to get company scope geolocation in mapConfig.js')
    return null
  }
}

const companyGeolocation = () => {
  try {
    if (configState &&
      configState.user &&
      configState.user.company &&
      configState.user.company.geolocation &&
      configState.user.company.geolocation.length &&
      configState.user.company.geolocation[0]
    ) {
      return latLng(configState.user?.company?.geolocation[0])
    }
    else {
      return null
    }
  }
  catch (e) {
    console.log('Error occurred while trying to get user geolocation in mapConfig.js')
    return null
  }
}
const companyGeolocationZoom = 14

export {
  defaultMapOptions,
  defaultMapSetup,
  defaultEuropeCenterAndZoom,
  defaultZagrebCenter,
  companyScopeGeolocation,
  companyGeolocation,
  companyGeolocationZoom
}
