import { api } from '@/global/services/api'
import { currentDate, getDateDifference, validateDateTimeFormat } from '@/global/services/helpers/dates'
import common from '../common'

const tableGridContentComponent = () => import('@/global/components/view-layouts/tabs-layout/components/tab-content-types/TableGridContent.vue')
const columnsContentComponent = () => import('@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue')
export default {
  name: 'vehicleOperationConfig',

  data () {
    return {
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      },
      signatureDialogData: {
        show: false,
        config: {},
        fetchSignature: true,
        signatureId: null,
        signatureType: null,
        instance: null,
        tabKey: ''
      },
      sendForSignatureDialogData: {
        show: false,
        title: this.$t('fleet/documents/prl_cargo.send_for_signature_title'),
        apiConfig: {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/request-signature'
        },
        documentId: this.$route.params.id,
        additionalObject: {},
        signatureType: null,
        instance: null,
        tabKey: ''
      },
      signatureDialogConfig: {},
      vehicleOperationDataPermissions: {},
      quantitiesPermissions: {}
    }
  },

  mixins: [
    common
  ],

  computed: {
    // Vehicle operation data main config
    vehicleOperationConfig () {
      return {
        title: this.$t('fleet/documents/disposition.vehicle_operation_data'),
        key: 'vehicle-operation-data',
        apiConfig: {
          get: {
            module: 'fleet',
            route: `travel-documents/disposition/${this.$route.params.id}/operation-data`,
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: `travel-documents/disposition/${this.$route.params.id}/operation-data`,
            method: 'post'
          }
        },
        permissions: this.vehicleOperationDataPermissions,
        onDataReceived: (data) => {
          const { meta } = data || {}
          const { permissions } = meta || {}
          this.$set(this.vehicleOperationDataPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
          this.$set(this.vehicleOperationDataPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
        },
        tabContent: [
          // Quantities table card
          {
            component: tableGridContentComponent,
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/disposition/${this.$route.params.id}/vehicle-operation`,
                method: 'get'
              }
            },
            permissions: this.quantitiesPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.quantitiesPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
              this.$set(this.quantitiesPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
            },
            rowClick: (event) => this.showQuantityDetails(event, 'showTableDetailsPage'),
            search_and_buttons_config: {
              additional_buttons: [
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                  prependIcon: 'mdi-file-pdf-box',
                  click: () => this.onQuantitiesViewPdf()
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.change_status'),
                  prependIcon: 'mdi-swap-horizontal',
                  visible: (event) => {
                    const { permissions } = event || {}

                    return permissions && permissions.canChangeStatus
                  },
                  click: (event) => this.onChangeStatus(event)
                }
              ],
              newClick: (event) => this.showQuantityNewPage(event, 'showTableNewPage')
            },
            title_and_back_config: {
              title: this.$t('fleet/documents/disposition.quantities_data'),
              showBackButton: {
                uiViewName: 'Disposition'
              }
            },
            filters_and_columns_visibility_config: {
              showRefreshButton: false,
              showFiltersButton: false,
              showColumnsVisibilityButton: false
            },
            tableConfig: {
              styles: {
                useFixedHeaderWidth: true,
                dataRowHeight: '64px',
                headersHeight: '60px',
                customStyle: {
                  minHeight: '500px'
                }
              },
              cellsConfig: {
                id: {
                  style: {
                    textAlign: 'center'
                  }
                },
                delivery_note: {
                  style: {
                    textAlign: 'left'
                  }
                },
                material_type: {
                  style: {
                    textAlign: 'left'
                  }
                },
                amount: {
                  style: {
                    textAlign: 'center'
                  }
                },
                amount_unit: {
                  custom_display_value: (data) => {
                    return data && data.amount_unit && data.amount_unit.description ? this.$t(data.amount_unit.description) : '-'
                  },
                  style: {
                    textAlign: 'center'
                  }
                },
                weight: {
                  style: {
                    textAlign: 'center'
                  }
                },
                weight_unit: {
                  custom_display_value: (data) => {
                    return data && data.weight_unit && data.weight_unit.description ? this.$t(data.weight_unit.description) : '-'
                  },
                  style: {
                    textAlign: 'center'
                  }
                }
              },
              headers: [
                {
                  label: this.$t('fleet/documents/prl_cargo.sr_no'),
                  key: 'id',
                  style: {
                    width: '80px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/disposition.delivery_note'),
                  key: 'delivery_note',
                  style: {
                    width: '271px',
                    textAlign: 'left'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/disposition.material_type'),
                  key: 'material_type',
                  style: {
                    width: '466px',
                    textAlign: 'left'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/disposition.quantity_m3'),
                  key: 'amount',
                  style: {
                    width: '160px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/machine.unit_of_measure'),
                  key: 'amount_unit',
                  style: {
                    width: '160px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/disposition.weight'),
                  key: 'weight',
                  style: {
                    width: '160px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/disposition.weight_unit'),
                  key: 'weight_unit',
                  style: {
                    width: '160px',
                    textAlign: 'center'
                  },
                  always_visible: true
                }
              ],
              actionsConfig: [
                {
                  label: this.$t('base.edit'),
                  icon: 'mdi-pencil-box',
                  visible: () => {
                    const { canEdit } = this.quantitiesPermissions || null

                    return !!canEdit
                  },
                  click: (event) => this.showQuantityDetails(event, 'showTableDetailsPage')
                },
                {
                  label: this.$t('base.delete'),
                  icon: 'mdi-delete',
                  visible: () => {
                    const { canEdit } = this.quantitiesPermissions || null

                    return !!canEdit
                  },
                  click: (event) => this.onVehicleOperationDataRemove(event)
                }
              ]
            }
          },
          // In total card
          {
            title: this.$t('fleet/documents/disposition.vehicle_operation_data'),
            subTitle: this.$t('fleet/documents/prl_cargo.total'),
            component: columnsContentComponent,
            showButtons: true,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'distance',
                    label: this.$t('fleet/documents/disposition.kilometers_traveled'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'started_at',
                    label: this.$t('fleet/documents/disposition.vehicle_operation_time_from'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock-outline',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    onInput: (statedAtInput, data) => {
                      // Destructure params from the text field onInput event callback function
                      //  formData -> current tab formData object containing all data of the tab
                      //  updateData -> global updateData function of the tab instance. Updates formData object with provided values
                      //  updateValidationErrors -> global updateValidationErrors function of the tab instance. Updates validationErrors object with provided values
                      const { formData, updateData, updateValidationErrors } = data || {}
                      // Date time format for formatting dates
                      const formedDateTimeFormat = 'DD.MM.YYYY HH:mm'
                      // Prepend input time with current date value because of finding time difference later
                      const formedStartedAtDateTime = currentDate() + ' ' + statedAtInput
                      // Same as for started_at time value
                      const formedCompletedAtDateTime = currentDate() + ' ' + formData?.completed_at
                      // Check if formatted date for started_at value is a valid date time value
                      const isValidStartedAtDateTimeFormat = validateDateTimeFormat(formedStartedAtDateTime, formedDateTimeFormat)
                      // Check if formatted date for completed_at value is a valid date time value
                      const isValidCompletedAtDateTimeFormat = validateDateTimeFormat(formedCompletedAtDateTime, formedDateTimeFormat)
                      // Check if updateData is function
                      const isUpdateDataFunc = typeof updateData === 'function'
                      // Check if updateValidationErrors is function
                      const isUpdateValidationErrorsFunc = typeof updateValidationErrors === 'function'
                      const validationErrorMessage = this.$t('base.invalid_date').replace(':format', 'HH:mm')

                      // If started_at time value is empty, reset its value and validation error message
                      if (!statedAtInput) {
                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }

                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: ''
                          })
                        }
                      }

                      // If started_at time value is set and its formed date time value is invalid, throw validation error message and reset total_time
                      if (statedAtInput && !isValidStartedAtDateTimeFormat) {
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: validationErrorMessage
                          })
                        }

                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                      // Else if completed_at time value is set and its formed date time value is invalid, throw validation error message and reset total_time
                      else if (formData?.completed_at && !isValidCompletedAtDateTimeFormat) {
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            completed_at: validationErrorMessage
                          })
                        }
                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                      // Else, calculate time difference in hours and set total_time value
                      else if (isUpdateDataFunc) {
                        const timeDifference = getDateDifference(formedStartedAtDateTime, formedCompletedAtDateTime, 'hour', formedDateTimeFormat)
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: '',
                            completed_at: ''
                          })
                        }

                        if (timeDifference >= 0) {
                          updateData({
                            total_time: timeDifference
                          })
                        }
                        else {
                          if (isUpdateValidationErrorsFunc) {
                            updateValidationErrors({
                              started_at: this.$t('base.start_date_less_than_end_date_message')
                            })
                          }
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'completed_at',
                    label: this.$t('fleet/documents/disposition.vehicle_operation_time_to'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock-outline',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    onInput: (completedAtInput, data) => {
                      // Destructure params from the text field onInput event callback function
                      //  formData -> current tab formData object containing all data of the tab
                      //  updateData -> global updateData function of the tab instance. Updates formData object with provided values
                      //  updateValidationErrors -> global updateValidationErrors function of the tab instance. Updates validationErrors object with provided values
                      const { formData, updateData, updateValidationErrors } = data || {}
                      // Date time format for formatting dates
                      const formedDateTimeFormat = 'DD.MM.YYYY HH:mm'
                      // Prepend input time with current date value because of finding time difference later
                      const formedCompletedAtDateTime = currentDate() + ' ' + completedAtInput
                      // Same as for started_at time value
                      const formedStartedAtDateTime = currentDate() + ' ' + formData?.started_at
                      // Check if formatted date for started_at value is a valid date time value
                      const isValidStartedAtDateTimeFormat = validateDateTimeFormat(formedStartedAtDateTime, formedDateTimeFormat)
                      // Check if formatted date for completed_at value is a valid date time value
                      const isValidCompletedAtDateTimeFormat = validateDateTimeFormat(formedCompletedAtDateTime, formedDateTimeFormat)
                      // Check if updateData is function
                      const isUpdateDataFunc = typeof updateData === 'function'
                      // Check if updateValidationErrors is function
                      const isUpdateValidationErrorsFunc = typeof updateValidationErrors === 'function'
                      const validationErrorMessage = this.$t('base.invalid_date').replace(':format', 'HH:mm')

                      // If completed_at time value is empty, reset its value and validation error message
                      if (!completedAtInput) {
                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }

                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: ''
                          })
                        }
                      }

                      // If completed_at time value is set and its formed date time value is invalid, throw validation error message and reset total_time
                      if (completedAtInput && !isValidCompletedAtDateTimeFormat) {
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            completed_at: validationErrorMessage
                          })
                        }

                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                      // Else if started_at time value is set and its formed date time value is invalid, throw validation error message and reset total_time
                      else if (formData?.started_at && !isValidStartedAtDateTimeFormat) {
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: validationErrorMessage
                          })
                        }
                        if (isUpdateDataFunc) {
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                      // Else, calculate time difference in hours and set total_time value
                      else if (isUpdateDataFunc) {
                        const timeDifference = getDateDifference(formedStartedAtDateTime, formedCompletedAtDateTime, 'hour', formedDateTimeFormat)
                        if (isUpdateValidationErrorsFunc) {
                          updateValidationErrors({
                            started_at: '',
                            completed_at: ''
                          })
                        }

                        if (timeDifference >= 0) {
                          updateData({
                            total_time: timeDifference
                          })
                        }
                        else {
                          if (isUpdateValidationErrorsFunc) {
                            updateValidationErrors({
                              completed_at: this.$t('base.end_date_greater_than_start_date_message')
                            })
                          }
                          updateData({
                            total_time: ''
                          })
                        }
                      }
                    },
                    appendOuterIcon: 'mdi-equal',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'total_time',
                    label: this.$t('fleet/documents/disposition.total_hours'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'order',
                    label: this.$t('fleet/documents/disposition.order'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Signatures card
          {
            subTitle: this.$t('fleet/documents/machine.signatures'),
            component: columnsContentComponent,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'disposer_signature_name',
                    row: 1,
                    column: 1,
                    label: this.$t('fleet/documents/disposition/vehicle_operation_data.disposer_signature_name'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'disposer_signed_at',
                    row: 1,
                    column: 2,
                    label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'driver_signature_name',
                    row: 1,
                    column: 3,
                    label: this.$t('fleet/documents/disposition/vehicle_operation_data.driver_signature_name'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'driver_signed_at',
                    row: 1,
                    column: 4,
                    label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  // Disposer signature image
                  {
                    type: 'content-with-buttons',
                    row: 2,
                    column: 1,
                    key: 'disposer_signature_image',
                    contentStack: 'horizontal',
                    contentType: 'image',
                    buttons: [
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                        },
                        visible: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_can_sign
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image
                        },
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image ? 'mdi-check' : 'mdi-draw'
                        },
                        color: this.$route.query.sign_button_color ?? '',
                        click: (event) => this.onSign(event, 'DISPOSITION_DISPOSER', true)
                      },
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                        },
                        visible: () => {
                          const { canEdit } = this.vehicleOperationDataPermissions || {}
                          return canEdit
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image
                        },
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.disposer_signature_image ? 'mdi-check' : 'mdi-send'
                        },
                        prependIconStyle: {
                          color: '#FFFFFF !important',
                          marginRight: '4px',
                          fontSize: '19px'
                        },
                        click: (event) => this.onDisposerSendForSignature(event)
                      }
                    ],
                    contentStyle: {
                      height: '88px',
                      minWidth: '270px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px',
                      marginRight: '10px'
                    },
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  // Driver signature image
                  {
                    type: 'content-with-buttons',
                    row: 2,
                    column: 3,
                    key: 'driver_signature_image',
                    buttons: [
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.driver_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                        },
                        visible: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.driver_can_sign
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.driver_signature_image
                        },
                        color: this.$route.query.sign_button_color ?? '',
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.driver_signature_image ? 'mdi-check' : 'mdi-draw'
                        },
                        click: (event) => this.onSign(event, 'DISPOSITION_DRIVER', true)
                      }
                    ],
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      minWidth: '270px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px',
                      marginRight: '10px'
                    },
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'recipient_signature_name',
                    row: 3,
                    column: 1,
                    label: this.$t('fleet/documents/disposition/vehicle_operation_data.recipient_signature_name'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'recipient_signed_at',
                    row: 3,
                    column: 2,
                    label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  // Recipient signature image
                  {
                    type: 'content-with-buttons',
                    row: 4,
                    column: 1,
                    key: 'recipient_signature_image',
                    buttons: [
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                        },
                        visible: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_can_sign
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image
                        },
                        color: this.$route.query.sign_button_color ?? '',
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image ? 'mdi-check' : 'mdi-draw'
                        },
                        click: (event) => this.onSign(event, 'DISPOSITION_RECIPIENT', true)
                      },
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                        },
                        visible: () => {
                          const { canEdit } = this.vehicleOperationDataPermissions || {}
                          return canEdit
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image
                        },
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.recipient_signature_image ? 'mdi-check' : 'mdi-send'
                        },
                        prependIconStyle: {
                          color: '#FFFFFF !important',
                          marginRight: '4px',
                          fontSize: '19px'
                        },
                        click: (event) => this.onRecipientSendForSignature(event)
                      }
                    ],
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      minWidth: '270px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px',
                      marginRight: '10px'
                    },
                    creatable: false,
                    editable: false,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    },

    // Quantities table details config
    quantityDetails () {
      return {
        title: this.$t('fleet/documents/disposition.quantities_data'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/disposition/${this.$route.params.id}/vehicle-operation`
              }
              else {
                return data && data.id ? `travel-documents/disposition/${this.$route.params.id}/vehicle-operation/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        tabContent: [
          {
            subTitle: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            showButtons: true,
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'delivery_note',
                    label: this.$t('fleet/documents/disposition.delivery_note'),
                    row: 1,
                    col: 1,
                    colSpan: 2,
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'material_type',
                    row: 1,
                    col: 3,
                    colSpan: 2,
                    label: this.$t('fleet/documents/disposition.material_type'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'amount',
                    label: this.$t('fleet/documents/prl_cargo.amount'),
                    row: 2,
                    col: 1,
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'amount_unit',
                    label: this.$t('fleet/documents/machine.unit_of_measure'),
                    type: 'autocomplete',
                    row: 2,
                    col: 2,
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/disposition-amount/autocomplete',
                      custom_display_value: (value) => {
                        const { description } = value || null
                        return description && typeof description === 'string' ? this.$t(description) : ''
                      },
                      returnValue: 'object',
                      form_value: 'id'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'weight',
                    label: this.$t('fleet/documents/disposition.weight'),
                    row: 2,
                    col: 3,
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'weight_unit',
                    label: this.$t('fleet/documents/disposition.weight_unit'),
                    type: 'autocomplete',
                    row: 2,
                    col: 4,
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/disposition-weight/autocomplete',
                      custom_display_value: (value) => {
                        const { description } = value || null
                        return description && typeof description === 'string' ? this.$t(description) : ''
                      },
                      returnValue: 'object',
                      form_value: 'id'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onCloseSendForSignatureDialog () {
      this.$set(this.sendForSignatureDialogData, 'show', false)
    },

    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    },

    // Remove vehicle movement row
    async onVehicleOperationDataRemove (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/disposition/vehicle_operation_data.confirm_delete'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/documents/disposition/vehicle_operation_data.confirm_delete_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onVehicleOperationDataRemoveCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async onVehicleOperationDataRemoveCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}

        if (id) {
          await api().fleet.delete(`travel-documents/disposition/${this.$route.params.id}/vehicle-operation/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchTableData && typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    onSign (event, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'tabKey', 'vehicle-operation-data')
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onDisposerSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'tabKey', 'vehicle-operation-data')
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'DISPOSITION_DISPOSER')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    onRecipientSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'tabKey', 'vehicle-operation-data')
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'DISPOSITION_RECIPIENT')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    // Show Quantities row details page
    showQuantityDetails (event, functionName) {
      const { index, instance } = event
      if (functionName && instance && typeof instance[functionName] === 'function') {
        instance[functionName](index, this.quantityDetails)
      }
    },

    // Show vehicle movement new page
    showQuantityNewPage (event, functionName) {
      if (functionName && event && typeof event[functionName] === 'function') {
        event[functionName](this.quantityDetails)
      }
    },

    async onQuantitiesViewPdf () {
      await this.exportData(this.$route.params.id)
    },

    async refreshInstance (tabKey) {
      const tabsForRefreshLocal = []

      if (tabKey && typeof tabKey === 'string') {
        tabsForRefreshLocal.push(tabKey)
      }

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async closeSignatureDialog () {
      this.$set(this.signatureDialogData, 'show', false)
    }
  }
}
