<template>
  <div>
    <!-- When extracting layers in separate component, key for same layer component should always
     be present (to force l-tile-layer rerender) as when updating attribution in l-tile-layer
     leaflet actually updates l-map component and cannot find parent if it is not direct child of l-map
     because for attribution vue wrapper uses this.$parent.mapObject.attributionControl -->
    <l-tile-layer
      v-if="mapType.type === 'osm'"
      key="osm"
      :url="mapType.url"
      :subdomains="mapType.subdomains"
      :attribution="mapType.attribution"
      :options="tileOptions"
    />
    <l-tile-layer-google
      v-if="mapType.type === 'google'"
      :key="mapType.key"
      :apikey="mapType.apiKey"
      :options="tileOptions"
      lang="hr"
      region="HR"
    />
    <l-tile-layer
      v-if="mapType.type === 'here'"
      key="here"
      :url="mapType.url"
      :attribution="mapType.attribution"
      :options="tileOptions"
    />
  </div>
</template>

<script>

import { LTileLayer } from 'vue2-leaflet'
import LTileLayerGoogle from '@/global/components/map/Vue2LeafletGoogleMutant'
import { createNamespacedHelpers } from 'vuex'
import { defaultMapSetup } from '@/global/common/mapConfig'

const { mapGetters, mapActions } = createNamespacedHelpers('map-type')

export default {
  name: 'MapTileLayers',
  components: {
    LTileLayer,
    LTileLayerGoogle
  },

  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      tileOptions: 'getTileOptions'
    })
  },

  watch: {
    mapType: {
      deep: true,
      handler (mapType) {
        if (mapType?.type === 'google') {
          this.setTileOptions({
            maxZoom: 20,
            maxNativeZoom: 20,
            type: mapType?.google_map_type || 'roadmap',
            layers: mapType?.layers ? mapType.layers : []
          })
        }
        else {
          this.setTileOptions({
            maxZoom: defaultMapSetup.maxZoom,
            maxNativeZoom: defaultMapSetup.maxNativeZoom
          })
        }
      }
    }
  },

  methods: {
    ...mapActions(['setMapType', 'setTileOptions'])
  }
}
</script>
