// This file contains common methods used in disposition documents
import { isObject } from 'lodash'
import { api } from '@/global/services/api'
import dayjs from 'dayjs'
export default {
  name: 'common',

  methods: {
    async onVehicleRegistrationSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          vehicle_brand: '',
          vehicle_garage_number: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'DISPOSITION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_garage_number: selectedItem.vehicle_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onVehicleGarageNumberSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          vehicle_brand: '',
          vehicle_registration: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'DISPOSITION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_registration: selectedItem.vehicle_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onTrailerRegistrationSelect (event) {
      const { selectedItem, instance, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: '',
          trailer_garage_number: '',
          trailer_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: selectedItem.trailer_brand || '',
          trailer_id: selectedItem.id || null
        }

        if (selectedItem.trailer_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'garage_number',
            vehicle_type: 'trailer',
            document_type: 'DISPOSITION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              trailer_garage_number: selectedItem.trailer_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            trailer_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onTrailerGarageNumberSelect (event) {
      const { selectedItem, instance, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: '',
          trailer_registration: '',
          trailer_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: selectedItem.trailer_brand || '',
          trailer_id: selectedItem.id || null
        }

        if (selectedItem.trailer_registration) {
          const { data: registrationData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'registration',
            vehicle_type: 'trailer',
            document_type: 'DISPOSITION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_registration', {
              data: registrationData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              trailer_registration: selectedItem.trailer_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            trailer_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    updateFormData (updateFunction, props = {}) {
      if (updateFunction && typeof updateFunction === 'function') {
        updateFunction(props)
      }
    },

    async exportData (documentId) {
      if (!documentId) {
        console.error('Document id is mandatory')
        return
      }

      const url = new URL(`${window.location.origin}/api/fleet/travel-documents/disposition/pdf-report`)
      let objectURL = null

      try {
        const response = await fetch(url.toString(), {
          method: 'POST',
          body: JSON.stringify({
            travel_document_id: documentId
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (!response.ok) {
          console.error(`Failed to show PDF: HTTP ${response.status} - ${response.statusText}`)
          return
        }

        const arrayBuffer = await response.arrayBuffer()

        // Create a Blob from the response data
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: 'application/pdf'
        })

        // Generate object URL for downloading
        objectURL = URL.createObjectURL(blob)
        const filename = `${this.$t('fleet.disposition')}_${dayjs().format('YYYYMMDDHHmmss')}.pdf`

        // Trigger download
        const link = document.createElement('a')
        link.href = objectURL
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      catch (exception) {
        console.error('Error showing pdf:', exception.message || exception)
      }
      finally {
        // Revoke object URL to release memory
        if (objectURL) {
          URL.revokeObjectURL(objectURL)
        }
      }
    }
  }
}
