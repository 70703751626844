<template>
  <v-dialog
    v-model="canOpenDialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : 1000"
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="elevation-0"
    persistent
  >
    <v-card
      class="form-fields-card"
    >
      <v-card-title
        style="background-color: #eeeeee;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeModal"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <div
        class="tabs-wrapper"
      >
        <v-tabs
          v-model="currentTab"
          :vertical="!$vuetify.breakpoint.mobile"
          @change="getClickedTab"
        >
          <template
            v-for="tab in tabs"
          >
            <v-tab
              :key="tab.key"
              style="height: 3rem; width: 10rem;"
              :class="$vuetify.breakpoint.mobile ? '' : 'justify-start'"
            >
              {{ tab.label }}
              <v-icon
                v-if="checkForValidationErrors(tab)"
                class="ml-1 red-muted--text"
              >
                mdi-alert-circle-outline
              </v-icon>
            </v-tab>

            <v-tab-item
              :key="tab.key + '_content'"
              eager
            >
              <v-card
                flat
              >
                <v-card-text
                  style="height: 70vh; overflow-y: scroll;"
                >
                  <prp-fields
                    :tab="tab"
                    :form-data="formData"
                    :confirmed-coordinates="confirmedCoordinates"
                    :edit-address="editAddress"
                    :edit-mode="editing"
                    :reset-location-modal-opening="reset"
                    :validation-errors="validationErrors"
                    :drop-down-values="dropDownValues"
                    :user-input="userInput"
                    :map-class="mapClass"
                    :upload-documents="uploadedDocuments"
                    @close-modal="closeModal"
                    @update-data="updateFormData"
                    @update-documents="updateDocuments"
                    @update-confirmed-coordinates="updateCoords"
                    @update-errors="updateErrors"
                    @update-location-modal-open-status="updateLocationModalOpenStatus"
                    @update-user-input="updateUserInput"
                    @save-data="saveData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </template>
        </v-tabs>
      </div>
      <v-card-actions
        class="justify-end"
        :class="$vuetify.breakpoint.mobile ? 'd-flex flex-column actions--mobile' : ''"
        style="background-color: #eeeeee; position: sticky; bottom: 0; z-index: 10;"
      >
        <v-btn
          class="error"
          :class="$vuetify.breakpoint.mobile ? 'mb-2 mr-0 ml-0' : 'mr-2'"
          :style="{ width: $vuetify.breakpoint.mobile ? '100%' : '' }"
          @click="closeModal"
        >
          {{ $t('road-maintenance.excavation_modal_close_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-file-remove
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          :class="$vuetify.breakpoint.mobile ? 'mb-2 mr-0 ml-0' : 'mr-2'"
          :style="{ width: $vuetify.breakpoint.mobile ? '100%' : '' }"
          :disabled="disableSave"
          @click="saveData"
        >
          {{ $t('road-maintenance.excavation_modal_save_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-content-save-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          :class="$vuetify.breakpoint.mobile ? 'mr-0 ml-0' : 'mr-4'"
          :style="{ width: $vuetify.breakpoint.mobile ? '100%' : '' }"
          :disabled="disableSave"
          @click="saveDataAndCloseModal"
        >
          {{ $t('road-maintenance.excavation_modal_save_and_close_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-content-save-move-outline
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import PrpFields from './PrpFields.vue'
import KeyBinder from '@/global/services/helpers/KeyBinder'
import { dataUriToBlob, convertToBase64 } from '@/global/services/helpers/files'
import { formatIsoDate, validateDateTimeFormat, convertDate } from '@/global/services/helpers/dates'
import { cloneDeep, isArray, isEmpty, isObject } from 'lodash'
import { isInteger } from 'lodash/lang'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/prp')

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
const DATE_TIME_FORMAT = 'D/M/YY HH:mm'
const IMAGE_FILE_TYPE = 'image/jpeg'
const PDF_FILE_TYPE = 'application/pdf'
const DISABLE_BUTTON_TIMEOUT_IN_MS = 1000
export default {
  name: 'ManagePrpModal',

  components: {
    PrpFields
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    editItemProp: {
      type: Object,
      default: () => ({})
    },
    editing: {
      type: Boolean,
      required: true
    },
    mapClass: {
      type: String,
      default: 'prp-location-map-modal'
    }
  },

  data () {
    return {
      currentTab: 0,
      canOpenDialog: false,
      validationErrors: {},
      formData: {},
      uploadedDocuments: {
        images: [],
        pdfFiles: []
      },
      formDataForSending: new FormData(),
      tabs: {
        request: {
          label: this.$t('road-maintenance/excavation-service/tabs.request'),
          key: this.$t('road-maintenance/excavation-service/tabs.request').toLowerCase(),
          columns: {
            applicant: {
              type: 'autocomplete',
              label: this.$t('road-maintenance/prp.request_applicant')
            },
            investor: {
              type: 'autocomplete',
              label: this.$t('road-maintenance/dropdown-types.investor'),
              autocomplete_options: {
                custom_display_value: (item) => {
                  if (item) {
                    return item.name && item.email ? item.name + '(' + item.email + ')' : (item.name ? item.name : null)
                  }
                  else {
                    return null
                  }
                }
              }
            },
            address: {
              type: 'address',
              label: this.$t('road-maintenance/prp.construction_site_address')
            },
            location: {
              type: 'modal',
              label: this.$t('road-maintenance/excavation-service/tabs/request/columns.location')
            },
            consent_number: {
              label: this.$t('road-maintenance/prp.consent_number')
            },
            start_date: {
              type: 'datetime-picker',
              format: DATE_TIME_FORMAT,
              custom_form_value: (value) => {
                return value ? convertDate(value, DATE_TIME_FORMAT, DEFAULT_DATE_TIME_FORMAT) : ''
              },
              label: this.$t('road-maintenance/prp.installation_date')
            },
            zc_responsible_person: {
              type: 'autocomplete',
              multiple: true,
              returnObject: true,
              custom_form_value: (value) => {
                return value && value.length ? value.map(item => item.id) : null
              },
              autocomplete_options: {
                custom_display_value: (item) => {
                  if (item) {
                    return item.name && item.email ? item.name + '(' + item.email + ')' : (item.name ? item.name : null)
                  }
                  else {
                    return null
                  }
                }
              },
              keepInput: false,
              label: this.$t('road-maintenance/prp.responsible_person_contact')
            },
            status: {
              type: 'autocomplete',
              items: [
                {
                  id: 'ACTIVE',
                  name: this.$t('road-maintenance/prp.active_status')
                },
                {
                  id: 'INACTIVE',
                  name: this.$t('road-maintenance/prp.inactive_status')
                }
              ],
              defaultValue: 'ACTIVE',
              label: this.$t('road-maintenance/excavation-service.status')
            },
            end_date: {
              type: 'datetime-picker',
              style: {
                backgroundColor: '#8f8d8d'
              },
              custom_form_value: (value) => {
                return value ? convertDate(value, DATE_TIME_FORMAT, DEFAULT_DATE_TIME_FORMAT) : ''
              },
              dark: true,
              format: DATE_TIME_FORMAT,
              label: this.$t('road-maintenance/prp.withdrawal_date')
            },
            extensions: {
              type: 'logData',
              title: this.$t('road-maintenance/prp.changes_history_for_withdrawal_date_title'),
              itemKey: 'end_date',
              custom_display_value: (date) => {
                return date && validateDateTimeFormat(date, DEFAULT_DATE_TIME_FORMAT) ? convertDate(date, DEFAULT_DATE_TIME_FORMAT, DATE_TIME_FORMAT) : null
              },
              hoverKey: 'updated_at'
            }
          }
        },
        documents: {
          label: 'Dokumenti',
          key: 'dokumenti',
          columns: {
            display_images: {
              type: 'display_images',
              numberOfPlaceholders: 3
            },
            display_pdf_files: {
              type: 'display_pdf_files',
              numberOfPlaceholders: 2
            }
          }
        }
      },
      editAddress: {},
      reset: false,
      confirmedCoordinates: {},
      locationModalOpenStatus: false,
      userInput: '',
      disableSave: false,
      imageNamesFromEdit: [],
      pdfNamesFromEdit: []
    }
  },

  computed: {
    ...mapGetters(['dropDownValues']),

    dialogTitle () {
      if (this.editing) {
        return this.$t('road-maintenance.excavation_updating_order_dialog_title')
      }
      return this.$t('road-maintenance.excavation_creating_order_dialog_title')
    }
  },

  watch: {
    isOpened: {
      immediate: true,
      handler (value) {
        this.canOpenDialog = value
        this.reset = value
        if (value) {
          this.resetVariables()
        }
      }
    },

    editItemProp: {
      immediate: true,
      deep: true,
      async handler (item) {
        this.resetVariables()
        for (const tabKey in this.tabs) {
          if (Object.prototype.hasOwnProperty.call(this.tabs, tabKey)) {
            const columns = this.tabs[tabKey].columns
            for (const columnKey in columns) {
              if (Object.prototype.hasOwnProperty.call(columns, columnKey)) {
                if (isArray(item[columnKey]) && item[columnKey]) {
                  this.$set(this.formData, columnKey, item[columnKey])
                }
                else if (isObject(item[columnKey]) && item[columnKey] !== null) {
                  this.$set(this.formData, columnKey, item[columnKey].id)
                }
                else {
                  if (columns[columnKey].type === 'datetime-picker' && item[columnKey]) {
                    // Get fields date format from config
                    const { format } = columns[columnKey] || {}

                    // Use fields date format if exists, otherwise use default date format 'YYYY-MM-DD'
                    const fieldDateFormat = format && typeof format === 'string' ? format : DEFAULT_DATE_TIME_FORMAT

                    // Set formatted date to text field that represents date
                    this.$set(this.formData, columnKey, formatIsoDate(item[columnKey], fieldDateFormat))
                  }
                  else if (columns[columnKey].type === 'address' && item.address && item.latitude && item.longitude) {
                    this.editAddress = { y: item.latitude, x: item.longitude, label: item.address }
                    this.userInput = item.address
                    this.$set(this.formData, columnKey, { y: item.latitude, x: item.longitude, label: item.address })
                  }
                  else {
                    this.$set(this.formData, columnKey, item[columnKey])
                  }
                }
              }
            }
          }
        }
        this.$set(this.formData, 'id', item.id)
        if (item.latitude && item.longitude) {
          this.$set(this.formData, 'location', item.latitude + ' ' + item.longitude)
        }

        if (item.record_images && item.record_images.length) {
          this.imageNamesFromEdit = []
          await Promise.all(item.record_images.map(async (url) => {
            try {
              const { fileContent, fileSize } = await this.getFileContent(url)
              const fileData = await fileContent
              if (fileData) {
                // Get the file name with extension, for example image.jpg
                const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1)
                // Get file name without extension, for example image
                const filenameWithoutExtension = filenameWithExtension.replace(/\.[^/.]+$/, '').replace('-imageConversion', '')
                this.uploadedDocuments.images.push({
                  name: filenameWithoutExtension,
                  url: fileData,
                  size: fileSize
                })
                this.imageNamesFromEdit.push({
                  name: filenameWithoutExtension,
                  size: fileSize
                })
              }
            }
            catch (error) {
              console.error('Error updating image content:', error)
            }
          }))
        }
        else {
          this.uploadedDocuments.images = []
        }
        if (item.record_pdfs && item.record_pdfs.length) {
          this.pdfNamesFromEdit = []
          await Promise.all(item.record_pdfs.map(async (url) => {
            try {
              const { fileContent, fileSize } = await this.getFileContent(url)
              const fileData = await fileContent
              if (fileData) {
                // Get the file name with extension, for example 'example-pdf.pdf'
                const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1)
                // Get file name without extension, for example 'example-pdf'
                const filenameWithoutExtension = filenameWithExtension.replace(/\.[^/.]+$/, '')
                this.uploadedDocuments.pdfFiles.push({
                  name: filenameWithoutExtension,
                  content: fileData,
                  size: fileSize
                })
                this.pdfNamesFromEdit.push({
                  name: filenameWithoutExtension,
                  size: fileSize
                })
              }
            }
            catch (error) {
              console.error('Error updating pdf file content:', error)
            }
          }))
        }
        else {
          this.uploadedDocuments.pdfFiles = []
        }
      }
    }
  },

  async created () {
    await this.fetchDropDownValues()
    this.keyCallbacks = {
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    ...mapActions(['fetchDropDownValues']),

    handleKeyDownEscape () {
      if (this.canOpenDialog && !this.locationModalOpenStatus) {
        this.closeModal()
      }
    },

    checkForValidationErrors (tab) {
      const validationErrorKeys = Object.keys(this.validationErrors)
      return Object.keys(tab.columns).some(fieldName => {
        return validationErrorKeys.includes(fieldName)
      })
    },

    getClickedTab (tabKey) {
      this.currentTab = tabKey
    },

    updateFormData (newData) {
      this.formData = newData
      if (newData.address && newData.address !== '') {
        this.$delete(this.validationErrors, 'address')
        const currentTab = this.currentTab
        this.currentTab = 1
        this.currentTab = currentTab
      }

      if (newData.location && newData.location !== '') {
        this.$delete(this.validationErrors, 'location')
      }
    },

    updateDocuments (newDocuments) {
      this.uploadedDocuments = newDocuments
    },

    updateUserInput (val) {
      this.userInput = val
    },

    updateCoords (val) {
      this.confirmedCoordinates = val
    },

    updateErrors (newErrors) {
      this.validationErrors = newErrors
    },

    closeModal () {
      this.resetVariables()
      this.$emit('close-modal')
    },

    resetVariables () {
      this.currentTab = 0
      this.formData = {}
      this.uploadedDocuments.images = []
      this.uploadedDocuments.pdfFiles = []
      this.imageNamesFromEdit = []
      this.pdfNamesFromEdit = []
      this.editAddress = {}
      this.userInput = ''
      this.confirmedCoordinates = {}
      this.validationErrors = {}
    },

    updateLocationModalOpenStatus (val) {
      this.locationModalOpenStatus = val
    },

    async saveData (callbackFunction) {
      const that = this
      const hasCallback = typeof callbackFunction === 'function'
      that.disableSave = true
      try {
        // Send data
        await this.sendData()

        // If the work order is opened in edit mode and saved without closing the popup (only save button), refresh the form with the updated data
        if (!hasCallback && this.editing && this.validateFormData()) {
          this.$emit('pull-fresh-edit-item', this.formData)
        }

        this.userInput = ''
        if (hasCallback) {
          callbackFunction(isEmpty(this.validationErrors))
        }
        setTimeout(() => {
          that.disableSave = false
        }, DISABLE_BUTTON_TIMEOUT_IN_MS)
      }
      catch (e) {
        console.log(e)
        if (hasCallback) {
          callbackFunction(false)
        }

        setTimeout(() => {
          that.disableSave = false
        }, DISABLE_BUTTON_TIMEOUT_IN_MS)
      }
    },

    async saveDataAndCloseModal () {
      await this.saveData((success) => {
        if (success) {
          this.closeModal()
        }
      })
    },

    async sendData () {
      if (!this.validateFormData()) {
        return
      }

      let response

      if (!this.editing) {
        this.populateFormDataForCreate()
        response = await api()['road-maintenance'].post('temporary-traffic-regulation', this.formDataForSending)
      }
      else {
        const updateFormData = this.prepareDataForUpdate()
        if (updateFormData.id && !isNaN(updateFormData.id)) {
          response = await api()['road-maintenance'].patch(`temporary-traffic-regulation/${this.formData.id}`, updateFormData)
        }
      }

      if (response && response.data && response.data.id) {
        const createFormDataFromFiles = async (files, formData, endpoint) => {
          if (files.length) {
            if (!this.editing || this.checkAreFilesChangedByNameAndSize(files, formData === imageFormData ? this.imageNamesFromEdit : this.pdfNamesFromEdit)) {
              for (const file of files) {
                const blob = dataUriToBlob(file.url || file.content)
                const fileType = formData === imageFormData ? IMAGE_FILE_TYPE : PDF_FILE_TYPE
                const newFile = new File([blob], file.name, { type: fileType })
                formData.append(formData === imageFormData ? 'images[]' : 'pdfs[]', newFile)
              }
              await api()['road-maintenance'].post(`${endpoint}`, formData)
            }
          }
          else {
            const existingFiles = formData === imageFormData ? this.imageNamesFromEdit : this.pdfNamesFromEdit
            if (existingFiles && existingFiles.length) {
              await api()['road-maintenance'].post(`${endpoint}`, formData)
              if (formData === imageFormData) {
                this.imageNamesFromEdit = []
              }
              else {
                this.pdfNamesFromEdit = []
              }
            }
          }
        }

        // Send images
        const imageFormData = new FormData()
        await createFormDataFromFiles(this.uploadedDocuments.images, imageFormData, `temporary-traffic-regulation/${response.data.id}/store-image`)

        // Send pdf files
        const pdfFileFormData = new FormData()
        await createFormDataFromFiles(this.uploadedDocuments.pdfFiles, pdfFileFormData, `temporary-traffic-regulation/${response.data.id}/store-pdf`)
      }

      this.validationErrors = {}
      this.currentTab = 0
      if (!this.editing) {
        this.formData = {}
        this.uploadedDocuments.images = []
        this.uploadedDocuments.pdfFiles = []
      }

      this.$emit('pull-data')
    },

    checkAreFilesChangedByNameAndSize (uploadedFiles, filesToCheck) {
      if (uploadedFiles.length !== filesToCheck.length) {
        return true
      }

      uploadedFiles.sort((a, b) => {
        if (a.name !== b.name) {
          return a.name.localeCompare(b.name)
        }
        else {
          return a.size - b.size
        }
      })

      filesToCheck.sort((a, b) => {
        if (a.name !== b.name) {
          return a.name.localeCompare(b.name)
        }
        else {
          return a.size - b.size
        }
      })

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i]
        const fileToCheck = filesToCheck[i]

        if (uploadedFile.name !== fileToCheck.name || uploadedFile.size !== fileToCheck.size) {
          return true
        }
      }

      return false
    },

    async getFileContent (imageUrl) {
      try {
        const response = await fetch(imageUrl)
        const blob = await response.blob()
        const fileSize = blob.size / (1024 * 1024) // File size in MB
        const fileContent = convertToBase64(blob)

        return { fileContent, fileSize }
      }
      catch (error) {
        console.error('Error fetching image:', error)
        return null
      }
    },

    validateFormData () {
      const { location = '', applicant, status, start_date: startDate } = this.formData
      const [latitude, longitude] = location.split(' ')
      const isLocationProvided = latitude && longitude

      // Validation rules
      const validationRules = [
        { field: 'location', isValid: isLocationProvided, message: this.$t('road-maintenance/prp.location_validation_error_message') },
        { field: 'address', isValid: isLocationProvided, message: this.$t('road-maintenance/prp.mandatory_field_message') },
        { field: 'applicant', isValid: !!applicant, message: this.$t('road-maintenance/prp.mandatory_field_message') },
        { field: 'status', isValid: !!status, message: this.$t('road-maintenance/prp.mandatory_field_message') },
        { field: 'start_date', isValid: !!startDate, message: this.$t('road-maintenance/prp.mandatory_field_message') }
      ]

      // Apply validation
      validationRules.forEach(({ field, isValid, message }) => {
        if (!isValid) {
          this.$set(this.validationErrors, field, message)
        }
      })

      return isEmpty(this.validationErrors)
    },

    populateFormDataForCreate () {
      this.formDataForSending = new FormData()
      const [latitude, longitude] = (this.formData.location || '').split(' ')
      this.formDataForSending.append('latitude', latitude)
      this.formDataForSending.append('longitude', longitude)

      for (const fieldKey in this.formData) {
        let fieldValue = this.formData[fieldKey]

        let fieldExistsInTabs = false
        for (const tabKey in this.tabs) {
          const tab = this.tabs[tabKey]
          if (fieldKey in tab.columns) {
            if (tab.columns[fieldKey].custom_form_value && typeof tab.columns[fieldKey].custom_form_value === 'function') {
              fieldValue = tab.columns[fieldKey].custom_form_value(fieldValue)
            }
            fieldExistsInTabs = true
            break
          }
        }

        if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
          if (fieldKey === 'address') {
            if (fieldValue && fieldValue.label) {
              this.formDataForSending.append(fieldKey, fieldValue.label)
            }
            else {
              this.formDataForSending.append(fieldKey, fieldValue)
            }
          }
          else if (!fieldExistsInTabs) {
            this.formDataForSending.append(fieldKey, fieldValue)
          }
          else {
            if (typeof fieldValue === 'string' || isInteger(fieldValue)) {
              this.formDataForSending.append(fieldKey, fieldValue)
            }
            else if (isArray(fieldValue)) {
              for (const arrayVal of fieldValue) {
                this.formDataForSending.append(fieldKey + '[]', arrayVal)
              }
            }
          }
        }
      }
    },

    prepareDataForUpdate () {
      const updateFormData = cloneDeep(this.formData)
      const [latitude, longitude] = (this.formData.location || '').split(' ')
      updateFormData.latitude = latitude
      updateFormData.longitude = longitude

      for (const key in this.formData) {
        let fieldValue = this.formData[key]

        for (const tabKey in this.tabs) {
          const tab = this.tabs[tabKey]
          if (key in tab.columns) {
            if (tab.columns[key].custom_form_value && typeof tab.columns[key].custom_form_value === 'function') {
              fieldValue = tab.columns[key].custom_form_value(fieldValue)
              updateFormData[key] = fieldValue
              break
            }
          }
        }

        if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
          delete updateFormData[key]
        }

        if (key === 'address' && fieldValue && fieldValue.label) {
          updateFormData[key] = fieldValue.label
        }
      }

      return updateFormData
    }
  }
}
</script>
