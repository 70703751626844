<template>
  <div style="display: none">
    <slot v-if="ready" />
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'

import { findRealParent, propsBinder } from 'vue2-leaflet'

export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    apikey: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: null
    },
    region: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    layerType: {
      type: String,
      default: 'base'
    },
    apiVersion: {
      type: String,
      default: 'quarterly'
    },
    visible: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      ready: false
    }
  },

  async mounted () {
    this.mapObject = L.gridLayer.googleMutant(this.options)
    L.DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, this.props)

    if (!(typeof window.google === 'object' && typeof window.google.maps === 'object')) {
      const googleApisScript = document.createElement('script')
      let scriptUrl = 'https://maps.googleapis.com/maps/api/js?key=' + this.apikey

      scriptUrl += this.apiVersion ? '&v=' + this.apiVersion : ''
      scriptUrl += this.lang ? '&language=' + this.lang : ''
      scriptUrl += this.region ? '&region=' + this.region : ''
      scriptUrl += '&loading=async'
      scriptUrl += '&callback=Function.prototype'

      googleApisScript.async = true
      googleApisScript.src = scriptUrl
      document.head.appendChild(googleApisScript)
    }

    for (const layers of this.options.layers) {
      if (['TrafficLayer', 'TransitLayer', 'BicyclingLayer'].includes(layers)) {
        this.mapObject.addGoogleLayer(layers)
      }
    }

    this.ready = true
    this.parentContainer = findRealParent(this.$parent)
    if (this.parentContainer) {
      this.parentContainer.addLayer(this, !this.visible)
    }
  },

  beforeDestroy () {
    if (this.parentContainer) {
      this.parentContainer.removeLayer(this)
    }
  },

  methods: {
    addLayer (layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject)
      }
    },
    removeLayer (layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject)
      }
    }
  }
}
</script>
