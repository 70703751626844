import L from 'leaflet'
import { api } from '@/global/services/api'
import colors from 'vuetify/lib/util/colors'

const commonIconDataObject = {
  iconSize: [27, 40],
  iconAnchor: [15, 40]
}

const state = {
  trips: [],
  viewConfig: {},
  tripsConfig: [],
  selectedTrips: [],
  selectedTripChunks: [],
  selectedTask: {},
  taskDetails: [],
  taskOverlap: [],
  routes: [],
  loading: false,
  loaderCount: 0,
  positionsLoading: false,
  companyScopeId: null,
  tripPositions: {},
  geolocations: [],
  tripAlarms: {},
  sensorActivityData: {},
  selectedVehicles: [],
  selectedVehiclesCount: 0,
  dateRange: {
    from: '',
    to: ''
  },
  checkboxes: {
    autoCenter: false,
    showDinStatus: false,
    showRouteAlarms: false,
    showUserLocations: false,
    showPartnerLocations: false
  },
  selectedDigitalInputType: null,
  userLocations: [],
  partnerLocations: [],
  vehicleColors: {},
  availableColors: [
    colors.red.base,
    colors.pink.base,
    colors.purple.base,
    colors.deepPurple.base,
    colors.indigo.base,
    colors.blue.base,
    colors.lightBlue.base,
    colors.cyan.base,
    colors.teal.base,
    colors.green.base,
    colors.lime.base,
    colors.amber.base,
    colors.orange.base,
    colors.deepOrange.base,
    colors.brown.base,
    colors.blueGrey.base,
    colors.grey.base
  ],
  tasks: [],
  alarmIcons: {
    overspeed: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-overspeed',
      html: '<div class="alarm-legal-overspeed-icon" />'
    }),
    legalOverspeed: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-legal-overspeed',
      html: '<div class="alarm-legal-overspeed-icon" />'
    }),
    engineRpmOverspeed: L.divIcon({
      ...commonIconDataObject,
      className: 'engine-rpm-overspeed',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    geofenceEnter: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-geofence-enter',
      html: '<div class="alarm-geofence-enter-icon" />'
    }),
    geofenceExit: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-geofence-exit',
      html: '<div class="alarm-geofence-exit-icon" />'
    }),
    deviceFuelDrop: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-device-fuel-drop',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    deviceInactive: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-device-inactive',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    lowPower: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-low-power',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    temperatureRange: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-temperature-range',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    door: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-temperature-range',
      html: '<div class="engine-rpm-overspeed-icon" />'
    }),
    deviceOverspeed: L.divIcon({
      ...commonIconDataObject,
      className: 'alarm-device-overspeed',
      html: '<div class="alarm-legal-overspeed-icon" />'
    })
  }
}

const getters = {
  trips: state => state.trips,
  viewConfig: state => state.viewConfig,
  tripsConfig: state => state.tripsConfig,
  routes: state => state.routes,
  getSelectedTrips: state => state.selectedTrips,
  getSelectedTripChunks: state => state.selectedTripChunks,
  getSelectedTask: state => state.selectedTask,
  loading: state => state.loading,
  positionsLoading: state => state.positionsLoading,
  selectedVehiclesCount: state => state.selectedVehiclesCount,
  dateRange: state => state.dateRange,
  checkboxes: state => state.checkboxes,
  userLocations: state => state.userLocations,
  partnerLocations: state => state.partnerLocations,
  selectedDigitalInputType: state => state.selectedDigitalInputType,
  alarmIcons: state => state.alarmIcons,
  tripAlarms: state => state.tripAlarms,
  tripPositions: state => state.tripPositions,
  geolocations: state => state.geolocations,
  sensorActivityData: state => state.sensorActivityData,
  companyScopeId: state => state.companyScopeId,
  tasks: state => state.tasks,
  taskDetails: state => state.taskDetails,
  taskOverlap: state => state.taskOverlap
}

const actions = {
  setSelectedVehicles ({ commit, dispatch }, vehicles) {
    commit('SET_SELECTED_VEHICLES', vehicles)
    dispatch('fetchConfig')
  },

  setSelectedTrips ({ commit, dispatch }, trips) {
    commit('SET_SELECTED_TRIPS', trips)
  },

  setSelectedTripChunks ({ commit, dispatch }, chunks) {
    commit('SET_SELECTED_TRIP_CHUNKS', chunks)
  },

  setSelectedTask ({ commit, dispatch }, task) {
    commit('SET_SELECTED_TASK', task)
  },
  setCompanyScopeId ({ commit }, companyScopeId) {
    commit('SET_COMPANY_SCOPE_ID', companyScopeId)
  },

  setSelectedVehiclesCount ({ commit, state }, count) {
    commit('SET_VEHICLE_COLORS', {})
    commit('SET_SELECTED_VEHICLES_COUNT', count)
  },

  setDateRange ({ commit }, dateRange) {
    commit('SET_DATE_RANGE', dateRange)
  },

  setLoading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  },

  setPositionsLoading ({ commit }, loading) {
    commit('SET_POSITIONS_LOADING', loading)
  },

  startLoader ({ commit, dispatch }) {
    commit('SET_LOADER_COUNT', state.loaderCount + 1)
    dispatch('setLoading', true)
  },

  stopLoader ({ commit, dispatch }) {
    if (state.loaderCount > 0) {
      commit('SET_LOADER_COUNT', state.loaderCount - 1)
    }
    if (state.loaderCount < 0) {
      commit('SET_LOADER_COUNT', 0)
    }
    if (state.loaderCount <= 0) {
      dispatch('setLoading', false)
    }
  },

  resetTrips ({ commit }) {
    commit('SET_TRIPS', [])
  },

  async fetchConfig ({ commit, state }) {
    try {
      const data = {
        vehicles: state.selectedVehicles,
        company_scope_id: state.companyScopeId
      }
      const { config } = await api('satellite-tracking')
        .get('tracking-history/config', data)

      commit('SET_VIEW_CONFIG', config)
      commit('SET_TRIPS_CONFIG', config.trips)

      commit('SET_CHECKBOXES', {
        showUserLocations: config.map_options.show_user_locations,
        showPartnerLocations: config.map_options.show_partner_locations
      })
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchTrips ({ commit, dispatch }, options) {
    dispatch('startLoader')

    commit('SET_SELECTED_TRIPS', [])
    commit('SET_TRIPS', [])
    commit('SET_ROUTES', [])

    try {
      const { data } = await api('road-maintenance')
        .post('/trips', options)

      commit('SET_TRIPS', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      dispatch('stopLoader')
    }
  },

  async fetchTaskDetails ({ commit, dispatch }, options) {
    dispatch('startLoader')

    commit('SET_SELECTED_TASK', [])
    commit('SET_TASK_DETAILS', [])

    try {
      const { data } = await api('road-maintenance')
        .post('/task-details', options)

      commit('SET_TASK_DETAILS', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      dispatch('stopLoader')
    }
  },

  async fetchTripPositions ({ commit, dispatch }, options) {
    let params = {}

    if (!options) {
      params = {
        vehicle_ids: state.selectedVehicles,
        from: state.dateRange.from,
        to: state.dateRange.to,
        company_scope_id: state.companyScopeId
      }
    }
    else {
      params = options.params
    }

    try {
      const { data } = await api('satellite-tracking')
        .get('tracking-history/positions', params)

      dispatch('updateTripPositions', {
        positions: data
      })
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      dispatch('stopLoader')
      if (state.positionsLoading) {
        dispatch('setPositionsLoading', false)
      }
    }
  },

  async setGeolocations ({ commit, dispatch }, options) {
    dispatch('updateGeolocations', {
      lat: options.lat,
      lng: options.lng,
      address: options.address
    })
  },

  async fetchTaskOverlap ({ commit, dispatch }, options) {
    dispatch('startLoader')

    commit('SET_SELECTED_TASK', [])
    commit('SET_SELECTED_TRIPS', [])

    try {
      const { data } = await api('road-maintenance')
        .post('task-overlap', options)

      commit('SET_TASK_OVERLAP', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      dispatch('stopLoader')
    }
  },

  async fetchReport ({ commit, dispatch }, options) {
    dispatch('startLoader')

    try {
      await api('road-maintenance')
        .post('task-overlap-report', options)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      dispatch('stopLoader')
    }
  },

  async fetchTripAlarms ({ commit, dispatch }, options) {
    try {
      const { data } = await api('satellite-tracking')
        .post('tracking-history/alarms', options.params)

      dispatch('updateTripAlarms', {
        alarms: data
      })
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchSensorActivityData ({ commit, dispatch }, options) {
    try {
      const { data } = await api('satellite-tracking')
        .post('tracking-history/sensor-activity', options.params)

      dispatch('updateSensorActivityData', {
        sensorData: data
      })
    }
    catch (e) {
      console.dir(e)
    }
  },
  async getTasks ({ commit, state }, loading = true) {
    if (loading) commit('SET_LOADING', true)
    try {
      const { data } = await api('road-maintenance')
        .get('tasks')
      commit('SET_TASKS', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
      if (loading) commit('SET_LOADING', false)
    }
  },

  updateTripPositions ({ commit }, payload) {
    commit('SET_TRIP_POSITIONS', payload)
  },

  updateGeolocations ({ commit }, payload) {
    commit('SET_GEOLOCATIONS', payload)
  },

  updateTripAlarms ({ commit }, payload) {
    commit('SET_TRIP_ALARMS', payload)
  },

  updateSensorActivityData ({ commit }, payload) {
    commit('SET_SENSOR_ACTIVITY_DATA', payload)
  },

  updateCheckboxes ({ commit, state, dispatch }, { field, value }) {
    commit('SET_CHECKBOXES', {
      ...state.checkboxes,
      [field]: value
    })

    dispatch('updateMapOptions')
  },

  async updateMapOptions ({ commit, state }) {
    try {
      const options = state.checkboxes

      await api()['satellite-tracking'].post('map-options', {
        uiView: 'tracking_history',
        map_options: {
          auto_center: options.autoCenter,
          show_din_status: options.showDinStatus,
          show_route_alarms: options.showRouteAlarms,
          show_user_locations: options.showUserLocations,
          show_partner_locations: options.showPartnerLocations
        }
      })
    }
    catch (e) {
      console.dir(e)
    }
  },

  setSelectedDigitalInputType ({ commit }, payload) {
    commit('SET_SELECTED_DIGITAL_INPUT_TYPE', payload)
  },

  async getUserLocations ({ commit }) {
    try {
      const { data } = await api()['satellite-tracking'].get('live-tracking/user-locations')

      commit('SET_USER_LOCATIONS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async getPartnerLocations ({ commit }) {
    try {
      const { data } = await api()['satellite-tracking'].get('live-tracking/partner-locations')

      commit('SET_PARTNER_LOCATIONS', data)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {

  SET_TRIPS: (state, payload) => (state.trips = payload),

  SET_VIEW_CONFIG: (state, payload) => (state.viewConfig = payload),

  SET_TRIPS_CONFIG: (state, payload) => (state.tripsConfig = payload),

  SET_ROUTES: (state, payload) => (state.routes = payload),

  SET_LOADING: (state, payload) => (state.loading = payload),

  SET_POSITIONS_LOADING: (state, payload) => (state.positionsLoading = payload),

  SET_LOADER_COUNT: (state, payload) => (state.loaderCount = payload),

  SET_VEHICLE_COLORS: (state, payload) => (state.vehicleColors = payload),

  SET_COMPANY_SCOPE_ID: (state, payload) => (state.companyScopeId = payload),

  SET_SELECTED_VEHICLES: (state, payload) => (state.selectedVehicles = payload),

  SET_SELECTED_TRIPS: (state, payload) => (state.selectedTrips = payload),

  SET_SELECTED_TRIP_CHUNKS: (state, payload) => (state.selectedTripChunks = payload),

  SET_SELECTED_TASK: (state, payload) => (state.selectedTask = payload),

  SET_TASK_DETAILS: (state, payload) => (state.taskDetails = payload),

  SET_TASK_OVERLAP: (state, payload) => (state.taskOverlap = payload),

  SET_SELECTED_VEHICLES_COUNT: (state, payload) => (state.selectedVehiclesCount = payload),

  SET_DATE_RANGE: (state, payload) => (state.dateRange = payload),

  SET_CHECKBOXES: (state, value) => (state.checkboxes = value),

  SET_SELECTED_DIGITAL_INPUT_TYPE: (state, value) => (state.selectedDigitalInputType = value),

  SET_USER_LOCATIONS: (state, value) => (state.userLocations = value),

  SET_PARTNER_LOCATIONS: (state, value) => (state.partnerLocations = value),

  SET_TRIP_POSITIONS: (state, value) => (state.tripPositions = value),

  SET_GEOLOCATIONS: (state, value) => (state.geolocations.push(value)),

  SET_TRIP_ALARMS: (state, value) => (state.tripAlarms = value),

  SET_SENSOR_ACTIVITY_DATA: (state, value) => (state.sensorActivityData = value),
  SET_TASKS: (state, value) => (state.tasks = value)

}

export default {
  state,
  getters,
  actions,
  mutations
}
