import common from '../common'

export default {
  name: 'plannedConfig',

  data () {
    return {
      plannedPermissions: {}
    }
  },

  mixins: [
    common
  ],

  computed: {
    planned () {
      return {
        title: this.$t('fleet/documents/prl_cargo.planned_completion'),
        key: 'planned',
        apiConfig: {
          post: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/planned/${this.$route.params.id}`,
            method: 'post'
          },
          get: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/planned/${this.$route.params.id}`,
            method: 'get'
          }
        },
        permissions: this.plannedPermissions,
        onDataReceived: (data) => {
          const { meta } = data || {}
          const { permissions } = meta || {}
          this.$set(this.plannedPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
          this.$set(this.plannedPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
        },
        tabContent: [
          // Daily scheduled card
          {
            title: this.$t('fleet/documents/prl_cargo.planned_completion'),
            subTitle: this.$t('fleet/documents/prl_cargo.daily_scheduled'),
            component: this.columnsContentComponent,
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            additional_buttons: [
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                prependIcon: 'mdi-file-pdf-box',
                click: () => this.onPlannedViewPdf()
              },
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.change_status'),
                prependIcon: 'mdi-swap-horizontal',
                visible: (event) => {
                  const { permissions } = event || {}

                  return permissions && permissions.canChangeStatus
                },
                click: (event) => this.onChangeStatus(event, 'planned')
              }
            ],
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'distance_planned',
                    label: this.$t('fleet/documents/prl_cargo.km'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true,
                    onInput: (value, data) => {
                      const { updateData, formData } = data || {}
                      if (formData && formData.weight_planned && value && updateData && typeof updateData === 'function') {
                        updateData({
                          planned_ratio: parseInt(formData.weight_planned) / parseInt(value)
                        })
                      }
                    }
                  },
                  {
                    key: 'weight_planned',
                    label: this.$t('fleet/documents/prl_cargo.tons'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true,
                    onInput: (value, data) => {
                      const { updateData, formData } = data || {}
                      if (formData && formData.distance_planned && value && updateData && typeof updateData === 'function') {
                        updateData({
                          planned_ratio: parseInt(value) / parseInt(formData.distance_planned)
                        })
                      }
                    }
                  },
                  {
                    key: 'planned_ratio',
                    label: this.$t('fleet/documents/prl_cargo.tons_km'),
                    field_type: 'number',
                    options: {
                      custom_display_value: (value) => {
                        return value ? parseFloat(value).toFixed(2) : ''
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Done card
          {
            subTitle: this.$t('fleet/documents/prl_cargo.done'),
            component: this.columnsContentComponent,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'distance_completed',
                    label: this.$t('fleet/documents/prl_cargo.km'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true,
                    onInput: (value, data) => {
                      const { updateData, formData } = data || {}
                      if (formData && formData.weight_completed && value && updateData && typeof updateData === 'function') {
                        updateData({
                          completed_ratio: parseInt(formData.weight_completed) / parseInt(value)
                        })
                      }
                    }
                  },
                  {
                    key: 'weight_completed',
                    label: this.$t('fleet/documents/prl_cargo.tons'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true,
                    onInput: (value, data) => {
                      const { updateData, formData } = data || {}
                      if (formData && formData.distance_completed && value && updateData && typeof updateData === 'function') {
                        updateData({
                          completed_ratio: parseInt(value) / parseInt(formData.distance_completed)
                        })
                      }
                    }
                  },
                  {
                    key: 'completed_ratio',
                    field_type: 'number',
                    label: this.$t('fleet/documents/prl_cargo.tons_km'),
                    options: {
                      custom_display_value: (value) => {
                        return value ? parseFloat(value).toFixed(2) : ''
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    async onPlannedViewPdf () {
      await this.exportData(this.$route.params.id)
    }
  }
}
